import mutations from './mutations'
import actions from './actions'

export default {
	namespaced: true,
	state: {
		activePage: 'start',
		activeSegment: null,
		oldNextSegment: null,
		invalidFields: [],
		locale: 'fi',
		/**
		 * List of filled segments in correct order
		 */
		history: []
	},
	getters: {
		activePage: state => state.activePage,
		activeSegment: state => state.activeSegment,
		invalidFields: state => state.invalidFields,
		locale: state => state.locale
  },
  mutations: mutations,
  actions: actions
}
