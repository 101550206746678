import Vue from 'vue'

export default {
	/**
	 * Set submission data 
	 * @param {Object} submission 
	 */
	setSubmission(state, submission) {
		state.submission = submission
	},
	/**
	 * Set submission answers
	 * @param {Array} answers 
	 */
	setAnswers(state, answers) {
		if (answers.length == 0) {
			state.answers = {}
		} else {
			let withOptions = answers.map(answer => {
				return answer.hasOwnProperty('options')
					? answer
					: {
						...answer,
						options: []
					}	
			})	
			let answersData = {}
			withOptions.forEach(answer => {
				answersData = {
					...answersData,
					[answer.field_id]: answer
				}
			})
			state.answers = answersData
		}
	},
	/**
	 * Add new anser to answers
	 * @param {Object} answer 
	 */
	addAnswer(state, answer) {
		state.answers = {
			...state.answers,
			[answer.field_id]: answer
		}
	},
	/**
	 * Edit answer's value
	 * @param {Object} payload 
	 */
	editValue(state, payload) {
		state.answers[payload.field_id].value = payload.value
	},
	/**
	 * Edit answer's value
	 * @param {Object} payload 
	 */
	editPassages(state, payload) {
		state.answers[payload.field_id].passages = payload.passages
	},
	/**
	 * Edit answer's value
	 * @param {Object} payload 
	 */
	editIsDone(state, payload) {
		state.answers[payload.field_id].is_done = payload.value
	},
	/**
	 * Edit answer's option
	 * @param {Object} payload 
	 */
	editOption(state, payload) {
		state.answers[payload.field_id].option_id = payload.value	
	},
	/**
	 * Edit answer's options
	 * @param {Object} payload 
	 */
	editOptionsList(state, payload) {
		state.answers[payload.field_id].options = payload.value
	},
	/**
	 * Set parent option to answer 
	 * @param {Object} payload 
	 */
	setParent(state, payload) {
		state.answers[payload.field_id].parent_option_id = payload.value	
	},
	/**
	 * Clear answer's option
	 * @param {String} field_id 
	 */
	clearOption(state, field_id) {
		state.answers[field_id].option_id = null
	},
	/**
	 * Clear answer's options
	 * @param {String} field_id 
	 */
	clearOptions(state, field_id) {
		state.answers[field_id].options = []	
		state.answers[field_id].option_id = null
		state.answers[field_id].parent_option_id = null	
	},
	/**
	 * Reset answers
	 */
	clearAnswers(state) {
		Object.assign(state.answers, {})		
	},
	/**
	 * Set given answers as axtra
	 * @param {Array} answers answer ids 
	 */
	setAnswersAsExtra(state,  answers) {
		answers.forEach(answer => {
			if (state.answers.hasOwnProperty(answer)) {
				Vue.set(state.answers[answer], 'is_extra', true)
			}
		})
	},
	/**
	 * Set answers as not extra 
	 * @param {Array} fields  
	 */
	setAnswersAsNotExtra(state, fields) {
		fields.forEach(field => {
			if (state.answers.hasOwnProperty(field.id)) {
				Vue.set(state.answers[field.id], 'is_extra', false)
			}
		})
	},
}
