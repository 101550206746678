<template>
  <div class="end-page d-flex flex-column justify-center mt-10 white--text px-5 px-sm-1">
    <div
      style="white-space: pre-line"
      class="mb-3"
      v-html="text"
    ></div>
    <div 
      class="field-label white--text text-center py-2" 
    >{{staticTranslation('labels.name')}}</div>
    <filler-text
      v-model="name"
      :invalid="invalidFields.includes('name')"
      :locale="locale"
    ></filler-text>
    <div 
      class="field-label white--text text-center py-2" 
    >{{staticTranslation('labels.email')}}</div>
    <filler-text
      v-model="email"
      :locale="locale"
      :errors="['Sähköposti ei kelpaa']"
      :invalid="invalidFields.includes('email')"
    ></filler-text>
  </div>
</template>
<script>
import FillerText from '@/components/filler-answer-components/FillerText'
import { mapGetters } from 'vuex'
import {translationMixins} from '@/mixins/translation-mixins'

export default {
  name: 'SurveyFillerLottery',
  mixins: [translationMixins],
  components: {
    FillerText
  },
  props: {
    lotteryEditSource: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      default: 'fi'
    },
    survey: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    text () {
      return this.survey.hasOwnProperty('translations')
        && this.survey.translations[this.locale].end_text !== null
        && this.survey.translations[this.locale].end_text !== ''
        ? this.survey.translations[this.locale].end_text
        : this.staticTranslation('texts.endText')
    },
    lottery () {
      return this.$store.getters[`${this.lotteryEditSource}/data`]
    },
    invalidFields () {
      return this.$store.getters[`${this.lotteryEditSource}/invalid`]
    },
    name: {
      /**
      * Getter method for the name
      * @return {String} 
      */
      get () {
        return this.lottery.name
      },
    
      /**
      * Setter method for the name 
      * @param {String} name 
      */
      set (name) {
        this.editValue({key: 'name', value: name})
      } 
    },
    /*last_name: {*/
      /**
      * Getter method for the last_name
      * @return {String} 
      */
      /*get () {*/
        /*return this.lottery.last_name*/
      /*},*/
    
      /**
      * Setter method for the last_name 
      * @param {String} last_name 
      */
      /*set (last_name) {*/
        /*this.editValue({key: 'last_name', value: last_name})*/
      /*} */
    /*},*/
    email: {
      /**
      * Getter method for the email
      * @return {String} 
      */
      get () {
        return this.lottery.email
      },
    
      /**
      * Setter method for the email 
      * @param {String} email 
      */
      set (email) {
        this.editValue({key: 'email', value: email})
      } 
    },
  },
  methods: {
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
    editValue (values) {
      this.$store.commit(`${this.lotteryEditSource}/editValue`, values)
    }
  }
}
</script>
