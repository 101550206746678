<template>
    <div class="map-passage">
      <div class="passage-details" @click="edit">
        <div class="departure-destination-container">
          <div class="departure">
            <span class="dd_letter">{{ getPointLetter(index, false) }}.</span>
            <span class="departure__name">{{ getDepartureName }}</span>
          </div>
          <v-icon>mdi-arrow-right</v-icon>
          <div class="destination">
            <span class="dd_letter">{{ getPointLetter(index, true) }}.</span>
            <span class="destination__name">{{ getDestinationName }}</span>
          </div>
        </div>
        <div class="transport-details">
          <div class="transport">
            <v-icon class="transport__icon">mdi-{{ passage.transport.icon }}</v-icon>
            <span class="transport__name"> {{ getTransportName }}</span>
          </div>

          <div class="distance-emission-container">
            <span class="distance">{{ getDistance }}</span>
            <span class="emission">{{ calculateEmission }}</span>
          </div>
        </div>
      </div>
      <button class="passage-remove" @click="showRemoveConfirmation = true">
        <v-icon color="#E47E67">mdi-trash-can-outline</v-icon>
      </button>

      <map-point-confirmation
          v-model="showRemoveConfirmation"
          icon="mdi-delete-circle-outline"
          :title="this.deleteHeader"
          :description="this.deleteText"
          :confirmText="this.deleteConfirmText"
          :cancelText="this.deleteCancelText"
          v-on:cancel="showRemoveConfirmation = false"
          v-on:confirm="removePassage"
        ></map-point-confirmation>
    </div>
</template>

<script>
import MapPointConfirmation from '@/components/MapPointConfirmation'
import { mapGetters } from 'vuex'
import { translationMixins } from '@/mixins/translation-mixins'

export default {
  name: 'MapPassageListCard',
  mixins: [translationMixins],
  components: {
    MapPointConfirmation
  },
  data () {
    return {
      showRemoveConfirmation: false
    }
  },
  props: {
    locale: String,
    passage: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    deleteHeader() {
      return this.staticTranslation('texts.deleteMapPointHeaderText')
    },
    deleteText() {
      return this.staticTranslation('texts.deleteMapPointText')
    },
    deleteConfirmText() {
      return this.staticTranslation('texts.buttonDeleteText')
    },
    deleteCancelText() {
      return this.staticTranslation('texts.buttonCancelText')
    },
    calculateEmission () {
      if(this.passage.distance == null || this.passage.emission == null) return ''

      return this.parseEmission(parseFloat((this.passage.emission * (this.passage.distance / 1000)) / 1000)) + ' kgCO2' // (emission * distance in km) / 1000
    },
    getTransportName() {
      return this.passage.option == null ? this.passage.transport.translations[this.locale].name : this.passage.option.translations[this.locale].name
    },
    getDepartureName () {
      return this.passage.departure.name !== null ? this.passage.departure.name : ''
    },
    getDestinationName () {
      return this.passage.destination.name !== null ? this.passage.destination.name : ''
    },
    getDistance () {
      return this.passage.distance !== null ? parseInt(this.passage.distance / 1000, 0).toString() + 'km' : ''
    },
  },
  methods: {
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
    parseEmission(emission) {
      return emission < 1 ? emission.toFixed(4) : emission.toFixed(1)
    },
    getPointLetter (index, dest) {
      const alphapet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      return alphapet.charAt(dest ? index + 1 : index)
    },
    removePassage () {
      this.$emit('remove-passage', this.passage, this.index)
      this.showRemoveConfirmation = false
    },
    edit () {
      this.$emit('edit-passage', this.passage, this.index)
    }
  },
}

</script>

<style lang="scss" scoped>
.map-passage {
  width: 100%;
  background-color: white;
  margin: 10px 0;
  display: flex;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  .passage-details {
    flex: 1;
    padding: 15px 20px;
    transition: all 0.2s linear;
    cursor: pointer;

    &:hover {
      background-color: #fdfdfd;
    }

    .departure-destination-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      .departure,
      .destination {
        &__name {
          margin-left: 5px;
        }
      }
    }

    .transport {
      display: flex;
      align-items: flex-end;

      &__icon {
        font-size: 1.6rem;
      }

      &__name {
        margin-left: 10px;
        line-height: 0.8rem;
        font-size: 0.8rem;
      }
    }
  }

  .transport-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .distance {
      margin-right: 10px;
      font-weight: bold;
    }
    .emission {
      font-color: #888;
      font-size: 0.8rem;
      font-weight: normal;
    }
  }

  .passage-remove {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>

