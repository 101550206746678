import LotteryApi from '@/services/api/filler/LotteryApi'
import Storage from '@/services/Storage'

export default {
	namespaced: true,
	state: {
		data: {
			name: null,
			email: null
		},
		invalid: []
	},
	getters: {
		data: state => state.data,
		invalid: state => state.invalid
  },
  mutations: {
		/**
		 * Edit lottery value
		 * @param {Object} payload 
		 */
		editValue(state, payload) {
			state.data = {
				...state.data,
				[payload.key]: payload.value
			}
			if (payload.value !== '' && payload.value !== null) {
				const index = state.invalid.indexOf(payload.key)
				if (index !== -1) {
					state.invalid.splice(index, 1)
				}
			}
		},
		/**
		 * Add to invalid list
		 * @param {String} key 
		 */
		addToInvalid(state, key) {
			if (!state.invalid.includes(key)) {
				state.invalid.push(key)
			}
		},
		/**
		 * Remove from invalid
		 * @param {String} key 
		 */
		removeFromInvalid(state, key) {
			const index = state.invalid.indexOf(key)
			if (index !== -1) {
				state.invalid.splice(index, 1)
			}
		},
  },
  actions: {
		/**
		 * Participate to lottery 
		 */
		async participate ({ state, commit, dispatch, rootGetters }) {
			await dispatch('validateFields')
			if (state.invalid.length === 0) {
				try {
					await LotteryApi.participate({
						...state.data,
						survey_id: rootGetters['survey/data'].id
					})
					const surveyData = rootGetters['survey/data']
					const survey = surveyData.id
					if (Storage.hasItem(`submission-${survey}`)) {
						let storageData = Storage.getItem(`submission-${survey}`)
						let newData = {
							...storageData,
							lottery_done: true
						}
						Storage.setItem(`submission-${survey}`, newData)
					}
					commit('filler/setActivePage', 'end', { root: true })
				} catch (err) {
					commit('filler/setActivePage', 'end', { root: true })
					throw err
				}
			}
		},
		/**
		 * Validate lottery fields 
		 */
		validateFields ({ state, commit }, ) {
			Object.keys(state.data).forEach(key => {
				if (
					state.data[key] === ''
					|| state.data[key] === null
				) {
					commit('addToInvalid', key)
				} else if (key !== 'email') {
					commit('removeFromInvalid', key)
				} else {
					const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					if (!regex.test(state.data[key])) {
						commit('addToInvalid', key)
					} else {
						commit('removeFromInvalid', key)
					}
				}
			})
		},
	}
}
