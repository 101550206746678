import actions from './actions'

export default {
	namespaced: true,
	state: {
		transports: [],
	},
	getters: {
		transports: state => state.transports
  },
  mutations: {
    setTransports(state, transports) {
      state.transports = transports
    }
  },
  actions: actions
}
