import Vue from 'vue'
import Vuex from 'vuex'
import { createStore } from 'vuex-extensions'
import actions from './actions'
import modules from './modules'

Vue.use(Vuex)

export default createStore(Vuex.Store, {
  state: {
		status: ''
  },
	getters: {
		status: state => state.status
	},
	mutations: {
		/**
		 * Set status
		 * @param {String} status 
		 */
		setStatus(state, status) {
			state.status = status
		},
	},
	actions: actions,
	modules: modules,
	plugins: []
});
