export const translationMixins = {
	methods: {
		/**
		 * Get segment, field or option translation
		 *
		 * @param {String} locale
		 * @param {String} item label or title
		 * @param {Array|Object} translations
		 * @return {String} 
		 */
		getTranslation (locale, item, translations) {
			if (Array.isArray(translations)) {
				const localeTranslation = translations.find(translation => translation.locale === locale)
				return localeTranslation ? localeTranslation[item] : ''
			} else if (typeof translations === 'object' && translations.hasOwnProperty(locale))  {
				return translations[locale][item]
			} else {
				return ''
			}	
		},

		getStaticTranslation (locale, key, translations, fallback) {
			if(Array.isArray(translations)) {
				const translation = translations.find(t => t.key == key)

				if(!translation 
					|| !translation.hasOwnProperty('translations')
					|| !translation.translations.hasOwnProperty(locale)
				) {
					return fallback
				}

				if(translation.translations[locale] === undefined || translation.translations[locale] === null) return fallback

				return translation.translations[locale]
			}
		}
	}
}
