<template>
  <div class="survey-filler-header d-flex justify-center">
    <div class="header-box">
      <img 
        class="header-box-image"
        :src="imgSource" 
        alt="">
    </div>
    <survey-filler-language-selector v-model="language" :locales="locales"></survey-filler-language-selector>
  </div>
</template>
<script>
import SurveyFillerLanguageSelector from './SurveyFillerLanguageSelector'

export default {
  name: 'SurveyFillerHeader',
  components: {
    SurveyFillerLanguageSelector,
  },
  props: {
    locale: {
      type: String,
      required: true
    },
    locales: {
      type: Array,
      required: true
    },
    imgSource: {
      type: String,
      required: true
    },
  },
  computed: {
    language: {
      /**
       * Getter method for the language
       * @return {String} 
       */
      get () {
        return this.locale
      },
    
      /**
       * Setter method for the language 
       * @param {String} language 
       */
      set (language) {
        this.$emit('changeLocale', language)
      } 
    }
  }
}
</script>
<style lang="scss" scoped>
.survey-filler-header {
  position: relative;
}

.header-box {
  position: relative;
  width: 80px;
  height: 80px;

  @media (min-width: 560px) {
    width: 150px;
    height: 150px;
  }

  .header-box-triangle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0; 
    height: 0; 
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
    border-top: 80px solid #000;

    @media (min-width: 560px) {
      border-left: 130px solid transparent;
      border-right: 130px solid transparent;
      border-top: 130px solid #000;
    }
  }

  .header-box-image {
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;

    @media (min-width: 560px) {
      top: 75px;
      width: 120px;
    }
  }
}
</style>
