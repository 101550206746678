<template>
  <v-select
    v-model="year"
    :items="selectableYears"
    :label="label"
    :error-messages="errorMessages"
    solo
  ></v-select>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import {translationMixins} from '@/mixins/translation-mixins'

export default {
  name: 'FillerYear',
  mixins: [translationMixins],
  props: {
    value: String,
    invalid: Boolean,
    locale: String
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    label () {
      return this.staticTranslation('labels.year')
    },
    year: {
      /**
      * Getter method for the year
      * @return {String} 
      */
      get () {
        return this.value  
      },
    
      /**
      * Setter method for the year 
      * @param {String} year 
      */
      set (year) {
        this.$emit('input', year)
      } 
    },
    /** 
     * Get the year that can be selected.
     *
     * @return Array
     */
    selectableYears () {
      var selectableYears = []
      for (let year = parseInt(moment().format('YYYY')) - 15; year >=1900; year--) {
        selectableYears.push(year.toString())
      }
      return selectableYears
    },
    errorMessages () {
      return this.invalid
        ? this.staticTranslation('messages.required') 
        : ''
    }
  },
  methods: {
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
  }
}
</script>
