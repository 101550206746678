<template>
  <v-dialog v-model="dialog" max-width="476px" persistent v-if="passage">
    <v-card>
      <div class="transport-dialog-wrapper">
        <div class="destinations">
          <div class="departure">
            <span>{{ passage.departure.marker !== null ? passage.departure.marker.label : '' }}.</span>
            <span>{{ passage.departure.name !== null ? passage.departure.name : '' }}</span>
          </div>
          <v-icon >mdi-arrow-right</v-icon>
          <div class="destination">
            <span>{{ passage.destination.marker !== null ? passage.destination.marker.label : '' }}.</span>
            <span>{{ passage.destination.name !== null ? passage.destination.name : '' }}</span>
          </div>
        </div>

        <div class="transports-container">
          <span class="transports-list-title" v-show="!shownOptions">{{ staticTranslation('dialogs.selectTransport') }}</span>
          <div class="transports-list" v-show="!showOptions">
            <button 
               class="transport-button active" 
               v-for="transport in transports" 
               :key="transport.id"
               @click="selectTransport(transport)">
              <v-icon class="transport-icon">mdi-{{ transport.icon }}</v-icon>
              <span>{{ transport.translations[locale].name }}</span>
            </button>
          </div>
          <transition name="smooth-enter">
            <div class="transport-options" :class="{selectionUnchanged: currentSelection === null}" v-if="showOptions">
              <div class="button-container">
                <button @click="cancelOptionSelection">
                  <v-icon>mdi-arrow-left</v-icon>
                  <span>{{ staticTranslation('labels.back') }}</span>
                </button>
              </div>

              <div class="currentSelection">
                <v-icon class="transport-icon">mdi-{{ currentTransport.icon }}</v-icon>
                <span>{{ currentTransport.translations[locale].name }}</span>
              </div>

              <span>{{ staticTranslation('dialogs.selectMotivePower') }}</span>
              <button 
                class="transport-option"
                :class="{active: isActiveTransportOption(option)}"
                v-for="option in shownOptions" @click="selectOption(option)" :key="option.id">
                <span>{{ option.translations[locale].name }}</span>
              </button>
            </div>
          </transition>
        </div>

        <button class="btn btn-cancel" @click="handleCancel">{{ staticTranslation('dialogs.cancel') }}</button>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import {translationMixins} from '@/mixins/translation-mixins'
export default {
  name: 'TransportEditDialog',
  mixins: [translationMixins],
  data () {
    return {
      currentSelection: null,
    }
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    header: {
      type: String
    },
    transports: {
      type: Array
    },
    departure: {
      type: String
    },
    arrival: {
      type: String
    },
    passage: {
      type: Object,
      required: false,
    },
    index: {
      type: Number,
      required: false,
    },
    locale: String
  },
  watch: {
    passage: function(newValue, oldValue) {
      this.currentSelection = newValue !== null ? newValue.transport : null
    },
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    dialog: {
      /**
       * Getter method for the dialog
       * @return {Boolean} 
       */
      get () {
        return this.value
      },
    
      /**
       * Setter method for the dialog 
       * @param {Boolean} dialog 
       */
      set (dialog) {
        this.$emit('input', dialog)
      } 
    },
    showOptions () {
      return this.currentSelection != null && this.currentSelection.options.length > 0
    },
    shownOptions () {
      if(this.currentSelection == null) {
        let options = this.transports.filter(transport => {
          return transport.id == this.passage.transport.id
        })

        if(options.length == 0) return []

        return options[0].options
      }

      return this.currentSelection.options
    },
    currentTransport () {
      return this.currentSelection == null ? this.passage.transport : this.currentSelection
    },
  },
  methods: {
    isActiveTransport(transport) {
      if(this.currentSelection == null) {
        return transport.id === this.passage.transport.id
      }

      return transport.id == this.currentSelection.id
    },
    isActiveTransportOption(option) {
      if(this.currentSelection !== null) return false

      return option.id === this.passage.option.id
    },
    selectTransport(transport) {
      if(transport.options.length < 1) {
        this.currentSelection = null
        this.$emit('transport-edited', { transport: transport, option: null, index: this.index })
        return
      }

      this.currentSelection = transport
    },
    selectOption(option) {
      // Same transport selected
      let transport = this.currentSelection == null ? this.passage.transport : this.currentSelection
      this.$emit('transport-edited', { transport: transport, option: option, index: this.index })
      this.currentSelection = null
    },
    handleCancel() {
      this.currentSelection = null
      this.$emit('cancel-dialog')
    },
    cancelOptionSelection() {
      this.currentSelection = null
    },
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
  }
}
</script>
<style lang="scss" scoped>
.transport-dialog-wrapper {
  z-index: 999;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .transports-container {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .transports-list-title {
      margin-bottom: 10px;
    }

    .transports-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;

      .transport-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px 10px;
        font-size: 0.75rem;
        transition: all 0.2s linear;
        width: 75px;

        &:focus {
          outline: none;
        }

        .transport-icon {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: #ddd;
          color: black;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.2rem;
          margin-bottom: 5px;
        }

        &.active {
          .transport-icon {
            background-color: #E47E67;
            color: white;
          }
        }
      }
    }

    .transport-options {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-height: 90vh;
      font-size: 0.85rem;
      width: 100%;

      .button-container {
        width: 100%;
        display: flex;
      }

      .currentSelection {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .transport-icon {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: #ddd;
          color: black;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.2rem;
          margin-bottom: 5px;
          margin-right: 10px;
        }
      }

      .transport-option {
        background-color: #E47E67;
        padding: 3px 20px;
        border-radius: calc(1em + 6px);
        margin-bottom: 10px;
        color: white;

        &:focus {
          outline: none;
        }

        &.active {
          background-color: hotpink;
        }
      }

      &.selectionUnchanged {
        .transport-option {
          background-color: #ddd;
          color: black;

          &.active {
            background-color: #E47E67;
            color: white;
          }
        }
      }
    }
  }

  .btn-cancel {
    background-color: #595959;
    color: white;
    padding: 7px 15px;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 5px;

    &:focus {
      outline: none;
    }
  }

  .destinations {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 0 20px;
    border-bottom: 1px solid #ddd;
  }
}

.smooth-enter-active, .smooth-leave-active {
  transition: max-height .5s;
}
.smooth-enter, .smooth-leave-to {
  max-height: 0;
}
</style>
