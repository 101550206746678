import SurveyApi from '@/services/api/filler/SurveyApi'
import Normalizer from '@/services/Normalizer'

export default {
	/**
	 * description 
	 * @param {} 
	 */
	async getSurveyData ({ commit, dispatch }) {
			const pathname = window.location.pathname.split('/')
			const target = pathname[1]
			const survey = pathname[2]
			const surveyData = await SurveyApi.getSurvey(target, survey)
			dispatch('setSurveyData', surveyData)
			return surveyData
	},
	/**
	 * Normalize survey's data and set to state 
	 * @param {Object} survey
	 */
	setSurveyData ({ commit, dispatch }, data) {
		//let segments = {}, fields = {}, options = {}
		if (data.segments.length) {
			const segments = _.cloneDeep(data.segments)
			commit('setNestedSegments', segments)
			//const normalized_segments = Normalizer.getSegmentEntities(survey_segments)
			//segments = normalized_segments.segments
			//if (normalized_segments.hasOwnProperty('fields')) {
				//fields = normalized_segments.fields
			//}
			//if (normalized_segments.hasOwnProperty('options')) {
				//options = normalized_segments.options
			//}
		} 
		commit('setData', data.survey)
		commit('setTarget', data.survey_target)
		//commit('setFields', fields)
		//commit('setOptions', options)
		commit('setJumps', data.jumps)
	},
}
