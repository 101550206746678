<template>
  <v-menu
    ref="menu1"
    v-model="datemenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :label="label"
        color="#000080"
        persistent-hint
        prepend-inner-icon="mdi-calendar"
        v-bind="attrs"
        @blur="date = parseDate(dateFormatted)"
        v-on="on"
        :error-messages="errorMessages"
        readonly
        solo
      ></v-text-field>
    </template>
    <v-date-picker 
      v-model="date" 
      color="#000080"
      no-title
      :allowed-dates="disableDates"
      :locale="locale"
      :first-day-of-week="1"
      @input="datemenu = false"></v-date-picker>
  </v-menu>
</template>
<script>
import { mapGetters } from 'vuex'
import {translationMixins} from '@/mixins/translation-mixins'
export default {
  name: 'FillerDate',
  mixins: [translationMixins],
  props: {
    value: String,
    field: Object, 
    locale: String,
    invalid: Boolean
  },
  data: vm => ({
    dateFormatted: null,
    datemenu: false,
  }),
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    date: {
      /**
      * Getter method for the date
      * @return {String} 
      */
      get () {
        return this.value
      },
    
      /**
      * Setter method for the date 
      * @param {String} date 
      */
      set (date) {
        this.$emit('input', date)
      } 
    },
    label () {
      return this.getStaticTranslation('labels.date')
    },
    errorMessages () {
      return this.invalid
        ? this.getStaticTranslation('messages.required') 
        : ''
    }
  },
  created () {
    this.dateFormatted = this.formatDate(this.date)
  },
  watch: {
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  methods: {
    disableDates(val){
      let afterInit = new Date();
      let beforeInit = new Date();
      let after = null;
      let before = null;
      if(this.field.max_date !== null){
        let afterDays = this.field.max_date * 7;
        after = new Date(afterInit.setDate(afterInit.getDate()+afterDays)).toISOString().substring(0,10)
      }
      if(this.field.min_date !== null){
        let beforeDays = this.field.min_date * 7;
        before = new Date(beforeInit.setDate(beforeInit.getDate()-beforeDays)).toISOString().substring(0,10)

      }
      if(after !== null && before === null){
        return val <= after;
      }else if ( after === null && before !== null){
        return val >= before;
      }else if ( after !== null && before !== null){
        return val >= before && val <= after;
      }else {
        return val;
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
  }
}
</script>
