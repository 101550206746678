<template>
  <div class="map-container">
    <google-map v-if="!this.isDone" :canAddMarkers="canAddMarkers" :markers="markers" :latestPoint="latestPoint"
      :locale="locale" :bgColor="bgColor" v-on:marker-added="addMarker" v-on:marker-moved="moveMarker"
      :lastPlaceName="lastPlaceName" v-on:marker-to-remove="markerToRemove" ref="googlemap"></google-map>
    <div class="map-wrapper">
      <div class="help-message-container" :style="{ 'background-color': bgColor }"
        v-if="showHelpMessage && helpMessage != '' && !this.isDone">
        <v-icon color="white">mdi-information-outline</v-icon>
        <span class="help-message">{{ this.helpMessage }}</span>
      </div>
      <div v-if="this.isDone"
        :style="{ 'display': 'flex', 'justify-content': 'center', 'padding-top': '5px', 'flex-flow': 'column' }">
        <div class="finish-text-container" :style="{ 'background-color': bgColor }">
          <span :style="{'font-size' : '18px'}">{{ this.tripFinishedText }}</span>
        </div>
        <p :style="{'text-align' : 'center', 'padding-top' : '5px'}">{{ this.tripEmissionText }}</p>
        <p :style="{'text-align' : 'center'}">{{ this.getEmission() + ' kgCO2' + ' (' + this.getEmissionPerKm() + ' kgCO2/km' + ')' }}</p>
      </div>
      <div v-if="this.isDone" :style="{ 'display': 'flex', 'justify-content': 'center', 'padding': '10px' }">
        <button class="final-routes-button" :style="{ 'background-color': bgColor }" @click="showRoute">
          {{ this.showRouteDone ? this.closePassagesText : this.showPassagesText }}
        </button>
      </div>
      <map-passage-list v-if="!this.isDone || this.showRouteDone" v-on:remove-passage="removePassage"
        v-on:edit-passage="editPassage" :passages="value" :locale="locale" :fillDestinations="fillDestinations"
        :isOneWay="isOneWay"></map-passage-list>

      <transport-dialog v-model="transportSelector" :passage="currentPassage" :locale="locale" :transports="transports"
        v-on:transport-selected="transportSelected" v-on:cancel-dialog="transportSelectionCancelled">
      </transport-dialog>

      <transport-edit-dialog v-model="transportEditor" :index="editableIndex" :passage="editablePassage"
        :locale="locale" :transports="transports" v-on:transport-edited="transportEdited"
        v-on:cancel-dialog="transportEditionCancelled">
      </transport-edit-dialog>

      <map-point-confirmation v-model="endpointSelector" icon="mdi-map-marker-path" :title="endpointTitle"
        :confirmText="confirmationYes" :cancelText="confirmationNo" v-on:cancel="handleEndpointCancel"
        v-on:confirm="handleDepartureEnd"></map-point-confirmation>

      <map-point-confirmation v-model="totalEndpointSelector" icon="mdi-map-marker-path" :title="totalEndpointTitle"
        :confirmText="confirmationYes" :cancelText="confirmationNo" v-on:cancel="handleTotalEndpointCancel"
        v-on:confirm="handleDestinationEnd"></map-point-confirmation>


      <map-way-back-confirmation v-model="showSameWayBackConfirmation" icon="mdi-map-marker-path" :title="sameWayTitle"
        :confirmText="confirmationYes" :oneWayText="oneWayText" :cancelText="confirmationNo"
        v-on:confirm="handleWayBack"></map-way-back-confirmation>

      <map-point-confirmation v-model="showRemoveConfirmation" icon="mdi-delete-circle-outline"
        :title="this.deleteHeader"
        :description="this.deleteText" :confirmText="this.deleteConfirmText"
        :cancelText="this.deleteCancelText" v-on:cancel="cancelRemove" v-on:confirm="markerRemoveConfirmed">
      </map-point-confirmation>
    </div>
    <div :style="{'padding': '5px'}">
      <div class="help-message-container" :style="{ 'background-color': bgColor }"
        v-if="showHelpMessage && helpMessage != '' && this.isDone">
        <v-icon color="white">mdi-information-outline</v-icon>
        <span class="help-message">{{ this.helpMessage }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import GoogleMap from '@/components/GoogleMap'
import MapPassageList from '@/components/MapPassageList'
import MapPointConfirmation from '@/components/MapPointConfirmation'
import MapWayBackConfirmation from '@/components/MapWayBackConfirmation'
import TransportDialog from '@/components/TransportDialog'
import TransportEditDialog from '@/components/TransportEditDialog'
import CalculateStraightDistance from '@/services/StraightLineDistance'
import { mapGetters } from 'vuex'
import { translationMixins } from '@/mixins/translation-mixins'

export default {
  name: 'FillerMap',
  mixins: [translationMixins],
  components: {
    GoogleMap,
    MapPassageList,
    MapPointConfirmation,
    MapWayBackConfirmation,
    TransportDialog,
    TransportEditDialog,
  },
  data() {
    return {
      lastPlaceName: "",
      toBeRemoved: null,
      showRemoveConfirmation: false,
      transportSelector: false,
      transportEditor: false,
      endpointSelector: false,
      totalEndpointSelector: false,
      showSameWayBackConfirmation: false,
      canAddMarkers: true,
      first_point: null,
      first_place: null,
      currentPassage: {
        departure: {
          name: '',
          marker: null
        },
        destination: {
          name: '',
          marker: null
        },
        transport: null,
        option: null,
        distance: null,
        emission: null,
        isWayBack: false
      },
      editablePassage: null,
      editableIndex: null,
      fillDestinations: false,
      distanceMatrix: null,
      isDone: false,
      showRouteDone: false,
      isOneWay: false,
    }
  },
  props: {
    value: Array,
    invalid: Boolean,
    locale: {
      type: String,
      default: 'fi'
    },
    bgColor: {
      type: String
    },
    errors: {
      type: Array,
      default: () => []
    },
    transports: {
      type: Array
    },
    answerEditSource: {
      type: String,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    doneValue: {
      type: Boolean
    }
  },
  mounted() {
    if (this.value.length > 0) {
      this.fillDestinations = this.value[this.value.length - 1].isWayBack == 1
    }

    this.distanceMatrix = new window.google.maps.DistanceMatrixService();
  },
  created() {

    if (this.doneValue != null) {
      this.isDone = this.doneValue
    }
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    errorMessages() {
      return this.invalid
        ? [...this.errors, ...['Täytä puuttuvat tiedot']]
        : ''
    },
    deleteHeader() {
      return this.staticTranslation('texts.deleteMapPointHeaderText')
    },
    deleteText() {
      return this.staticTranslation('texts.deleteMapPointText')
    },
    deleteConfirmText() {
      return this.staticTranslation('texts.buttonDeleteText')
    },
    deleteCancelText() {
      return this.staticTranslation('texts.buttonCancelText')
    },
    helpMessage() {
      if (this.isDone) {
        return this.staticTranslation('messages.mapFillEnded')
      }

      if (this.latestPoint == null) {
        return this.staticTranslation('messages.mapFirstPoint')
      }
      return this.staticTranslation('messages.mapNextPoint')
    },
    tripEmissionText() {
      return this.staticTranslation('texts.tripEmissionText')
    },
    tripFinishedText() {
      return this.staticTranslation('texts.tripFinishedText')
    },
    showPassagesText() {
      return this.staticTranslation('texts.showPassagesText')
    },
    closePassagesText() {
      return this.staticTranslation('texts.closePassagesText')
    },
    markers() {
      let markers = []

      // No added passages yet
      if (this.value.length == 0) {
        if (this.currentPassage.departure.marker == null) return []
        markers.push(this.currentPassage.departure.marker)

        if (this.currentPassage.destination.marker == null) return markers
        markers.push(this.currentPassage.destination.marker)

        return markers
      }

      // Add all finished passages departures
      markers = this.value.map(passage => {
        return passage.departure.marker
      })

      markers.push(this.value[this.value.length - 1].destination.marker)

      if (this.currentPassage.destination.marker == null) return markers
      markers.push(this.currentPassage.destination.marker)

      return markers
    },
    latestPoint() {
      return this.value.length > 0 ? this.value[this.value.length - 1].destination.marker : this.first_point
    },
    latestPlace() {
      return this.value.length > 0 ? this.value[this.value.length - 1].destination.name : this.first_place
    },
    showHelpMessage() {
      return true
    },
    confirmationYes() {
      return this.staticTranslation('dialogs.yes')
    },
    oneWayText() {
      return this.staticTranslation('dialogs.oneWay')
    },
    confirmationNo() {
      return this.staticTranslation('dialogs.no')
    },
    endpointTitle() {
      return this.staticTranslation('dialogs.endpointTitle')
    },
    totalEndpointTitle() {
      return this.staticTranslation('dialogs.totalEndpointTitle')
    },
    sameWayTitle() {
      return this.staticTranslation('dialogs.sameWayTitle')
    },
  },
  methods: {
    getEmission() {
      let totalEmission = 0;
      this.value.forEach(function (val) {
        let emission = (parseFloat((val.emission * (val.distance / 1000)) / 1000))
        if (emission < 1) {
          emission = emission.toFixed(4)
        } else {
          emission = emission.toFixed(1)
        }
        totalEmission = parseInt(totalEmission) + parseInt(emission)
      })
      return totalEmission
    },
    getEmissionPerKm() {
      let totalKm = 0
      this.value.forEach(function (val) {
        totalKm = parseInt(totalKm) + parseInt((val.distance / 1000))
      })
      let coPerKm = (parseInt(this.getEmission()) / parseInt(totalKm))
      if (coPerKm < 1) {
        coPerKm = coPerKm.toFixed(4)
      } else {
        coPerKm = coPerKm.toFixed(1)
      }
      return coPerKm
    },
    showRoute() {
      this.showRouteDone = !this.showRouteDone
    },
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
    handleMapClick() {
      this.transportSelector = true
    },
    async transportSelected(selected) {
      // Generate rest of the data and add to passages
      this.transportSelector = false

      if (!this.fillDestinations && !this.isOneWay) {
        this.endpointSelector = true
      } else {
        this.totalEndpointSelector = true
      }

      this.currentPassage.transport = selected.transport
      this.currentPassage.option = selected.option
      this.currentPassage.distance = await this.getDistance(this.currentPassage.departure.marker, this.currentPassage.destination.marker, selected.transport, selected.option)
      this.currentPassage.emission = selected.option == null ? selected.transport.emission : selected.option.emission
      this.value.push({ ...this.currentPassage, isWayBack: this.fillDestinations })

      this.clearCurrentPassage()
    },
    transportSelectionCancelled() {
      this.transportSelector = false
      this.clearCurrentPassage()
      if (this.value.length == 0) {
        this.first_point = null
      }
    },
    async transportEdited(selected, index) {
      this.transportEditor = false
      this.editablePassage = null

      // Validate
      if (selected.index > this.value.length - 1) return

      this.value[selected.index].transport = selected.transport
      this.value[selected.index].option = selected.option
      this.value[selected.index].distance = await this.getDistance(this.value[selected.index].departure.marker, this.value[selected.index].destination.marker, selected.transport, selected.option)
    },
    transportEditionCancelled() {
      this.transportEditor = false
      this.editablePassage = null
    },
    handleEndpointCancel(value) {
      this.endpointSelector = false
      this.isDone = false
      this.$emit('update-finished-state', false)
    },
    handleTotalEndpointCancel(value) {
      this.totalEndpointSelector = false
      this.isDone = false
      this.$emit('update-finished-state', false)

    },
    markerToRemove(marker) {
      // if (this.value.length > 0) {
      this.toBeRemoved = marker
      this.showRemoveConfirmation = true
      // }
    },
    cancelRemove() {
      this.showRemoveConfirmation = false
      this.toBeRemoved = null;
    },
    markerRemoveConfirmed() {
      for (let index = 0; index < this.value.length; index++) {
        if (this.toBeRemoved.label == this.value[index].departure.marker.label || this.toBeRemoved.label == this.value[index].destination.marker.label) {
          this.removePassage(null, index)
        }
      }
      if (this.value.length == 0) {
        this.first_point = null
        this.clearCurrentPassage()
      }
      this.showRemoveConfirmation = false
      this.toBeRemoved = null
      this.lastPlaceName = ""
    },
    moveMarker(marker, place) {
      let updateVals = [];
      let allVals = [];
      this.value.forEach((val) => {
        let toUpdate = false
        if (val.departure.marker.label == marker.label) {
          val.departure.marker.lat = marker.getPosition().lat()
          val.departure.marker.lng = marker.getPosition().lng()
          val.departure.name = place
          toUpdate = true
        }
        if (val.destination.marker.label == marker.label) {
          val.destination.marker.lat = marker.getPosition().lat()
          val.destination.marker.lng = marker.getPosition().lng()
          val.destination.name = place
          toUpdate = true
        }
        if (toUpdate) {
          updateVals.push(val)
        }
        allVals.push(val);
      })
      if (this.currentPassage.departure.marker != null) {
        if (this.currentPassage.departure.marker.label == marker.label) {
          this.currentPassage.departure.marker.lat = marker.getPosition().lat()
          this.currentPassage.departure.marker.lng = marker.getPosition().lng()
          this.currentPassage.departure.name = place
        }
      }
      if (this.currentPassage.destination.marker != null) {
        if (this.currentPassage.destination.marker.label == marker.label) {
          this.currentPassage.destination.marker.lat = marker.getPosition().lat()
          this.currentPassage.destination.marker.lng = marker.getPosition().lng()
          this.currentPassage.destination.name = place
        }
      }
      if (updateVals.length > 0) {
        updateVals.forEach(async element => {
          element.distance = await this.updateDistance(element)
        })
      }
      // this.value = null
      this.$emit('update-value-prop', allVals)
      this.lastPlaceName = place
    },
    async updateDistance(value) {
      var distance = await new Promise((resolve, reject) => {
        let transitModes = ['bus', 'rail', 'subway', 'train', 'tram']
        let travel = null;
        let transit = null;
        if (transitModes.some(data => data === value.transport.transit)) {
          transit = value.transport.transit
          travel = 'transit';
        } else {
          travel = value.transport.transit
          transit = null;
        }
        this.distanceMatrix.getDistanceMatrix({
          origins: [new window.google.maps.LatLng(value.departure.marker.lat, value.departure.marker.lng)],
          destinations: [new window.google.maps.LatLng(value.destination.marker.lat, value.destination.marker.lng)],
          travelMode: travel != null ? travel.toUpperCase() : null,
          transitOptions: transit !== null ? { modes: [transit.toUpperCase()] } : null,
        }, (response, status) => {
          if (status == 'OK') {
            if (response.rows.length < 1) resolve(null)

            let totalDistance = 0
            for (var i = 0; i < response.rows.length; i++) {
              for (var j = 0; j < response.rows[i].elements.length; j++) {
                if (response.rows[i].elements[j].status == 'ZERO_RESULTS') break
                totalDistance += response.rows[i].elements[j].distance.value
              }
            }

            if (totalDistance > 0) resolve(totalDistance)
          }
          resolve(null)
        })
      })
      if (distance === null) {
        distance = CalculateStraightDistance({ lat: value.departure.marker.lat, lng: value.departure.marker.lng }, { lat: value.destination.marker.lat, lng: value.destination.marker.lng })
      }
      return distance
    },
    addMarker(marker, place) {
      if (this.first_point == null && this.value.length == 0) {
        this.$refs.googlemap.openFullScreen()

        this.first_point = {
          lat: marker.getPosition().lat(),
          lng: marker.getPosition().lng(),
          label: marker.label
        }

        this.currentPassage.departure.marker = {
          lat: marker.getPosition().lat(),
          lng: marker.getPosition().lng(),
          label: marker.label
        }
        this.currentPassage.departure.name = place
      } else {
        this.transportSelector = true
        //this.closeFullScreen()

        if (this.value.length == 0) {
          this.currentPassage.destination.marker = {
            lat: marker.getPosition().lat(),
            lng: marker.getPosition().lng(),
            label: marker.label
          }
          this.currentPassage.destination.name = place
        } else {
          this.currentPassage.departure.marker = {
            lat: this.latestPoint.lat,
            lng: this.latestPoint.lng,
            label: this.latestPoint.label
          }
          this.currentPassage.departure.name = this.latestPlace
          this.currentPassage.destination.marker = {
            lat: marker.getPosition().lat(),
            lng: marker.getPosition().lng(),
            label: marker.label
          }
          this.currentPassage.destination.name = place
        }
      }
      this.lastPlaceName = place
    },
    clearCurrentPassage() {
      this.currentPassage = {
        departure: {
          name: '',
          marker: null
        },
        destination: {
          name: '',
          marker: null
        },
        transport: null,
        option: null,
        distance: null,
        emission: null,
        isWayBack: this.fillDestinations
      }
    },
    handleDepartureEnd() {
      this.endpointSelector = false
      if (!this.isOneWay) {
        this.showSameWayBackConfirmation = true
      }
    },
    handleDestinationEnd() {
      this.totalEndpointSelector = false
      this.isDone = true
      this.$emit('update-finished-state', true)
      this.$refs.googlemap.closeFullScreen()
    },
    changeWay() {
    },
    removePassage(passage, index) {
      this.fillDestinations = this.value[index].isWayBack == 1
      this.value.splice(index, Infinity)

      if (this.value.length == 0) {
        this.first_point = null
      }
      this.isDone = false
      this.$emit('update-finished-state', false)
      this.isOneWay = false
    },
    editPassage(passage, index) {
      this.editablePassage = passage
      this.editableIndex = index
      this.transportEditor = true
    },
    handleWayBack(status) {
      console.log(status)
      if (status == 'confirm') {
        this.handleSameWayBack()
        this.fillDestinations = true
        this.currentPassage.isWayBack = true
        this.isDone = true
        this.$emit('update-finished-state', true)
        this.isOneWay = false
        this.$refs.googlemap.closeFullScreen()
        return
      } else if (status == 'oneWay') {
        this.isOneWay = true
        this.isDone = true
        this.$emit('update-finished-state', true)
        this.showSameWayBackConfirmation = false
        this.$refs.googlemap.closeFullScreen()
        return
      }
      this.fillDestinations = true
      this.currentPassage.isWayBack = true
      this.showSameWayBackConfirmation = false
    },
    handleSameWayBack() {
      this.showSameWayBackConfirmation = false

      let newPassages = []
      let tmpPassage = null

      this.value.forEach(passage => {
        tmpPassage = {
          departure: {
            name: passage.destination.name,
            marker: passage.destination.marker,
            label: ''
          },
          destination: {
            name: passage.departure.name,
            marker: passage.departure.marker,
            label: ''
          },
          transport: passage.transport,
          option: passage.option,
          distance: passage.distance,
          emission: passage.emission,
          isWayBack: true
        }

        newPassages = [tmpPassage, ...newPassages]
      })

      newPassages = newPassages.map((passage, index) => {
        return {
          ...passage,
          departure: {
            ...passage.departure,
            label: this.getMarkerLetter(this.value.length + index)
          },
          destination: {
            ...passage.destination,
            label: this.getMarkerLetter(this.value.length + index + 1)
          }
        }
      })

      this.isDone = true
      this.$emit('update-finished-state', true)

      this.$store.commit(`${this.answerEditSource}/editPassages`, {
        field_id: this.field.id,
        passages: [...this.value, ...newPassages],
      })
    },
    async getDistance(departure, destination, transport, option) {
      // If has option use that
      // Else use transport (parent)
      let transit = (option !== null && option.transit !== null)
        ? option.transit
        : ((transport !== null && transport.transit !== null)
          ? transport.transit
          : null)

      let distance = null
      let mode = null
      let transitMode = null
      let modes = ['bicycling', 'driving', 'transit', 'walking']
      let transitModes = ['bus', 'rail', 'subway', 'train', 'tram']
      // If not straight line or calculation fails use helper
      if (transit !== null) {
        if (transitModes.includes(transit)) {
          mode = 'transit'
          transitMode = transit
        } else if (modes.includes(transit)) {
          mode = transit
        }
      }

      // Use Google Maps Distance Matrix API
      if (mode !== null && this.distanceMatrix !== null) {
        distance = await new Promise((resolve, reject) => {
          this.distanceMatrix.getDistanceMatrix({
            origins: [new window.google.maps.LatLng(departure.lat, departure.lng)],
            destinations: [new window.google.maps.LatLng(destination.lat, destination.lng)],
            travelMode: mode.toUpperCase(),
            //travelMode: 'DRIVING',
            transitOptions: transitMode !== null ? { modes: [transitMode.toUpperCase()] } : null,
          }, (response, status) => {
            if (status == 'OK') {
              if (response.rows.length < 1) resolve(null)

              let totalDistance = 0
              for (var i = 0; i < response.rows.length; i++) {
                for (var j = 0; j < response.rows[i].elements.length; j++) {
                  if (response.rows[i].elements[j].status == 'ZERO_RESULTS') break
                  totalDistance += response.rows[i].elements[j].distance.value
                }
              }

              if (totalDistance > 0) resolve(totalDistance)
            }
            resolve(null)
          })
        })
      }

      // Use Helper to calculate straight line
      if (distance === null || mode === null) {
        distance = CalculateStraightDistance({ lat: departure.lat, lng: departure.lng }, { lat: destination.lat, lng: destination.lng })
      }

      return distance
    },
    getMarkerLetter(index) {
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      return index > alphabet.length - 1 ? index : alphabet.charAt(index)
    },
  }
}
</script>
<style lang="scss" scoped>
.final-routes-button {
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  color: white;

}

.map-container {
  width: 100%;
  background-color: white;

  .map {
    width: 100%;
    height: 200px;
    background-color: #ddd;
  }

  .map-wrapper {
    padding: 0 10px 10px;
  }

  .finish-text-container {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
    color: white;

  }

  .help-message-container {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    color: white;

    .help-message {
      margin-left: 10px;
    }
  }
}
</style>
