<template>
  <div @click="activateSlider">
    <v-slider
    v-model="sliderValue"
    class="mt-12"
    track-color="#FFFFFF"
    track-fill-color="#FFFFFF"
    thumb-color="white"
    :thumb-label=" 'always'"
    :thumb-size="50"
    :ticks="ticks"
    :tick-size="6"
    :min="field.min ? field.min : 0"
    :max="field.max ? field.max : 1"
    :step="step"
    :validate-on-blur="true"
    >
    <template v-slot:prepend>
      <span>{{field.min}}</span>
    </template>
    <template v-slot:append>
      <span>{{field.max}}</span>
    </template>
  </v-slider>
  <p v-if="invalid" :style="{ 'color': 'white', 'textAlign': 'center' }">{{ this.staticTranslation('messages.required') }}</p>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import {translationMixins} from '@/mixins/translation-mixins'

export default {
  name: 'FillerSlider',
  mixins: [translationMixins],
  created(){
    if(this.activated){
      this.dataActivated = true;
    }
    if(!this.field.is_required){
      this.dataActivated = true;
    }
    if(this.value === null && this.field.initial !== null){
      this.newVal = this.field.initial;
    }else if(this.value === null && this.field.initial === null){
      this.newVal = this.field.min;
    }else {
      this.newVal = this.value;
    }

  },
  data() {
    return {
    dataActivated : true,
    newVal : null
    }
  },  
  props: {
    field: Object, 
    value: String,
    invalid: Boolean,
    activated : Boolean,
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    sliderValue: {
      /**
      * Getter method for the sliderValue 
      * @return {Number} 
      */
      get () {
        if(this.dataActivated){
          return parseFloat(this.newVal)
        }
      },
    
      /**
      * Setter method for the sliderValue 
      * @param {String} value 
      */
      set (value) {
         if(this.dataActivated){
            this.$emit('input', value.toString()) 
          }
      } 
    },
    ticks () {
      return (Number.isInteger(Number(this.field.step))
        && Number(this.field.max) <= 10)
        ? 'always'
        : false
    },
    step () {
      if (this.field.step) {
        return this.field.step
      } else if (
        this.field.max !== null
        && this.field.min !== null
      ) {
        if (Number.isInteger(this.field.max)) {
          return 1
        } else {
          return this.field.max / 10
        }
      } else {
        return 1
      }
    }
  },
  methods: {
    activateSlider(event){
      if(!this.dataActivated){
        event.preventDefault();
        this.dataActivated = true;
      }
    },
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
  },
}
</script>
<style>
.v-input__append-outer span{
  color:white;
}
.v-input__prepend-outer span{
  color:white;
}
.v-slider__thumb-label div span{
  color:black;
}
.v-slider__tick{
  background-color:white;
}
</style>
