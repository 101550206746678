<template>
  <div class="filler-options ">
    <div 
      v-if="invalid"
      class="d-flex justify-center mb-2 white--text"
    >{{ staticTranslation('texts.chooseOne') }}</div>
    <div 
      v-if="answer.parent_option_id !== null || answerOption !== null && answerOption.asks_value"
      class="selected-options d-flex"
    >
      <v-btn 
        v-if="answer.parent_option_id" 
        rounded
        dark
        color="light-blue"
        class="mb-4"
        @click="clearOptions"
      >
        {{parentOptionLabel}} 
        <v-icon class="ml-2">mdi-close</v-icon>
      </v-btn>
      <v-btn 
        v-if="answerOption !== null && answerOption.asks_value"
        rounded
        dark
        color="light-blue"
        class="mb-4 ml-2"
        @click="clearOption"
      >
        {{answerOptionLabel}} 
        <v-icon class="ml-2">mdi-close</v-icon>
      </v-btn>
    </div>
    <div 
      v-if="answerOption !== null && answerOption.asks_value"
      class="extra-value">
      <v-text-field
        v-model="answerValue"
        solo
        color="#000080"
        :label="answerOptionQuestion"
      ></v-text-field>
    </div>
    <div 
      v-else-if="answer.parent_option_id !== null"
      class="child-options">
      <div
        class="child-options d-flex flex-wrap justify-space-between"
      >
        <filler-option-box
          v-for="option in childOptions"
          :key="option.id"
          :option="option"
          :isChosen="isChosen(option.id)"
          @choose="chooseOption"
          :locale="locale"
        ></filler-option-box>
      </div>
    </div>
    <div 
      v-else
      class="parent-options d-flex flex-wrap justify-space-between"
    >
      <filler-option-box
        v-for="option in parentOptions"
        :key="option.id"
        :option="option"
        :isChosen="isChosen(option.id)"
        @choose="chooseOption"
        :locale="locale"
      ></filler-option-box>
    </div>
  </div>
</template>
<script>
import FillerOptionBox from './FillerOptionBox'
import { mapGetters } from 'vuex'
import {translationMixins} from '@/mixins/translation-mixins'

export default {
  name: 'FillerOptions',
  mixins: [translationMixins],
  props: {
    value: {
      type: String
    },
    field: {
      type: Object,
      required: true
    },
    segment: {
      tpe: Object,
      required: true
    },
    answer: {
      type: Object
    },
    answerEditSource: {
      type: String,
      required: true
    },
    itemsSource: {
      type: String,
    },
    preview: {
      type: Boolean,
      required: true
    },
    locale: {
      type: String,
      default: 'fi'
    },
    invalid: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FillerOptionBox
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    options () {
      return this.field.options
    },
    answerValue: {
      /**
      * Getter method for the value
      * @return {String} 
      */
      get () {
        return this.value
      },
    
      /**
      * Setter method for the value 
      * @param {String} value 
      */
      set (answerValue) {
        this.$emit('input', answerValue)
      } 
    },
    parentOptions () {
      /*let options = this.itemOptions(this.field.options)*/
      /*let parentOptions = options.filter(option => option.parent_id === null)*/
      /*return parentOptions.sort((a, b) => a.sort_order - b.sort_order)*/

      let parentOptions = this.options.filter(option => option.parent_id === null)
      return parentOptions.sort((a, b) => a.sort_order - b.sort_order)
    },
    childOptions () {
      if (this.parentOption) {
        return this.options.filter(option => {
          return option.parent_id === this.parentOption.id
        }).sort((a, b) => {
          return a.sort_order - b.sort_order
        })
      } else {
        return null
      }
      /*return this.parentOption*/
        /*? this.itemOptions(this.parentOption.children_ids)*/
        /*: null*/
    },
    answerOption () {
      return this.answer.option_id
        ? this.getOption(this.answer.option_id)
        : null
    },
    answerOptions () {
      return this.answer.options
    },
    parentOption () {
      return this.answer.parent_option_id
        ? this.getOption(this.answer.parent_option_id)
        : null
    },
    answerOptionLabel () {
      return this.getLabel(this.answerOption, 'label')
    },
    answerOptionQuestion () {
      return this.getLabel(this.answerOption, 'value_question')
    },
    parentOptionLabel () {
      return this.parentOption
        ? this.getLabel(this.parentOption, 'label')
        : ''
    }
  },
  methods: {
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
    getLabel (option, item) {
      const optionTranslation = option.translations.find(translation => translation.locale === this.locale)
      return optionTranslation ? optionTranslation[item] : ''
    },
    itemOptions (list) {
      if (this.preview) {
        if (this.segment.forced) {
          return this.$store.getters[`surveySection/itemOptions`](list)
        } else if (this.segment.is_common) {
          return this.$store.getters['commonSegment/option/itemOptions'](list)
        } else {
          return this.$store.getters[`${this.itemsSource}/option/itemOptions`](list)
        }
      } else {
          return this.$store.getters['survey/itemOptions'](list)
      }
    },
    getOption (option_id) {
      const option = this.options.find(option => option.id === option_id)
      return option ? option : null
      if (this.preview) {
        if (this.segment.forced) {
          return this.$store.getters[`surveySection/getOption`](option_id)
        } else if (this.segment.is_common) {
          return this.$store.getters['commonSegment/option/get'](option_id)
        } else {
          return this.$store.getters[`${this.itemsSource}/option/get`](option_id)
        }
      } else {
        return this.$store.getters['survey/getOption'](option_id)
      }
    },
    chooseOption (option) {
      if (this.field.answer_type === 'select') {
        if (option.children_ids.length > 0) {
          this.$store.commit(`${this.answerEditSource}/setParent`, {
            field_id: this.field.id,
            value: option.id
          }) 
        } else {
          //Set only option_id if answer_type is 'select'
          this.$store.commit(`${this.answerEditSource}/editOption`, {
            field_id: this.field.id,
            value: option.id
          })
          if (this.invalid) {
            this.$emit('setValid') 
          }
          this.$emit('nextBySelect', {
            field: this.field,
            option: option
          })
        }
      } else if (this.field.answer_type === 'multiselect') {
        //Set all the options if answer_type is 'multiselect'
        let options = [...this.answer.options]
        let index = options.indexOf(option.id)
        if (index !== -1) {
          options.splice(index, 1)
        } else {
          options = options.concat([option.id])
        }
        this.$store.commit(`${this.answerEditSource}/editOptionsList`, {
          field_id: this.field.id,
          value: options
        }) 
        if (this.invalid) {
          this.$emit('setValid') 
        }
      }
    },
    clearOptions () {
      this.$store.commit(`${this.answerEditSource}/clearOptions`, this.field.id)
    },
    clearOption () {
      this.$store.commit(`${this.answerEditSource}/clearOption`, this.field.id)
    },
    isChosen (option_id) {
      return (this.answerOption !== null && (option_id === this.answerOption.id))
      || this.answer.options.indexOf(option_id) !== -1
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
