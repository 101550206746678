<template>
  <div class="bg" style="width:100vw;height:100vh">
    <div class="centered">
      <v-progress-circular 
        v-if="status === 'loading'"
        class="loading"
        indeterminate size="64">
      </v-progress-circular>
      <div 
        v-if="status === 'error' || status === 'notFound'"
        class="not-found">
        <h1>Kyselyä ei löytynyt</h1>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FillerLoadingScreen',
  props: {
    status: {
      required: true,
      type: String
    } 
  }
}
</script>
<style lang="scss" scoped>
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
