import actions from './actions'
import mutations from './mutations'

export default {
	namespaced: true,
	state: {
		submission: {},
		answers: {}
	},
	getters: {
		getAnswer: state => field_id => state.answers[field_id],
		submission: state => state.submission,
		answers: state => state.answers
  },
  mutations: mutations,
  actions: actions 
}
