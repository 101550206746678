<template>
  <div class="start-page d-flex flex-column justify-center mt-6 mt-md-4 white--text px-5 px-sm-1">
    <h1>{{ welcomeText }}</h1>
    <div style="white-space: pre-line" v-html="text"></div>
    <address v-if="surveyTarget !== null" class="target-address mt-6">
      {{ targetStreetAddress }}<br>
      {{ zipCodeAndCity }}
    </address>
    <div v-if="imgSource" class="survey-filler-header">
      <div class="header-box">
        <div class="d-flex justify-center">
          <img class="header-box-image" :src="imgSource" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { translationMixins } from '@/mixins/translation-mixins'
export default {
  name: 'SurveyFillerStart',
  mixins: [translationMixins],
  props: {
    locale: String,
    survey: {
      type: Object
    },
    imgSource: {
      type: String,
      required: true
    },
    surveyTarget: {
      type: [Object, null]
    }
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    welcomeText() {
      return this.staticTranslation('texts.welcome')
    },
    targetStreetAddress() {
      return this.surveyTarget !== null && this.surveyTarget.street_address !== null
        ? this.surveyTarget.street_address
        : ''
    },
    zipCodeAndCity() {
      const zipCode = this.surveyTarget !== null
        && this.surveyTarget.zip_code !== null
        && this.surveyTarget.zip_code !== ''
        ? this.surveyTarget.zip_code
        : null
      const city = this.surveyTarget !== null
        && this.surveyTarget.city !== null
        && this.surveyTarget.city !== ''
        ? this.surveyTarget.city
        : null

      return zipCode && city
        ? `${zipCode}, ${city}`
        : null
    },
    text() {
      return this.survey.hasOwnProperty('translations')
        && this.survey.translations[this.locale].start_text !== null
        && this.survey.translations[this.locale].start_text !== ''
        ? this.survey.translations[this.locale].start_text
        : this.staticTranslation('texts.startText')
    }
  },
  methods: {
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
  },
}
</script>
<style lang="scss" scoped>
.survey-filler-header {
  position: relative;
}

.header-box {
  .header-box-image {
    padding-top: 10px;
    width: 150px;
    height: auto;

    @media (min-width: 560px) {
      top: 75px;
      width: 200px;
      height: auto;
    }
  }
}

.start-page {
  font-family: 'Josefin Sans', sans-serif !important;
}
</style>
