<template>
  <div class="end-page d-flex flex-column justify-center mt-10 white--text px-5 px-sm-1">
    <div v-if="survey.lottery_active" style="white-space: pre-line" v-html="endText"></div>
    <div v-if="!survey.lottery_active" style="white-space: pre-line" class="mb-3" v-html="text"></div>
    <div v-if="imgSource" class="survey-filler-header d-flex justify-center">
      <div class="header-box">
        <img class="header-box-image" :src="imgSource" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { translationMixins } from '@/mixins/translation-mixins'
export default {
  name: 'SurveyFillerEnd',
  mixins: [translationMixins],
  props: {
    imgSource: {
      type: String,
      required: true
    },
    locale: String,
    survey: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    text() {
      return this.survey.hasOwnProperty('translations')
        && this.survey.translations[this.locale].end_text !== null
        && this.survey.translations[this.locale].end_text !== ''
        ? this.survey.translations[this.locale].end_text
        : this.staticTranslation('texts.endText')
    },
    endText() {
      return this.staticTranslation('texts.endText')
    },
  },
  methods: {
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
  },
}
</script>
<style lang="scss" scoped>
.survey-filler-header {
  position: relative;
}

.header-box {
  .header-box-image {
    padding-top: 10px;
    width: 150px;
    height: auto;

    @media (min-width: 560px) {
      top: 75px;
      width: 150px;
      height: auto;
    }
  }
}

.end-page {
  font-family: 'Josefin Sans', sans-serif !important;
}
</style>
