<template>
  <div 
    class="d-flex flex-column justify-center mt-6 mt-md-4 white--text px-5 px-sm-1"
  >
    <div
      style="white-space: pre-line"
      v-html="text"
    ></div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {translationMixins} from '@/mixins/translation-mixins'

export default {
  name: 'SurveyFillerTerms',
  mixins: [translationMixins],
  props: {
    locale: {
      type: String,
      default: 'fi'
    },
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    text () {
      return this.staticTranslation('texts.lotteryTerms')
    }
  },
  methods: {
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
  },
}
</script>
