<template>
  <div class="language-selector">
    <v-select v-model="locale" :items="survey_locales" dark color="white" base-color="white">
      <template v-slot:item="{ item }">
        <country-flag v-if="item.value === 'en'" country="gb" style="margin: 0 !important"></country-flag>
        <country-flag v-else-if="item.value === 'sv'" country="se" style="margin: 0 !important"></country-flag>
        <country-flag v-else :country="item.value" style="margin: 0 !important"></country-flag>
        <div class="pr-4">{{ item.text }}</div>
      </template>
    </v-select>
  </div>
</template>
<script>
import CountryFlag from 'vue-country-flag'

export default {
  name: 'SurveyFillerLanguageSelector',
  components: {
    CountryFlag
  },
  props: {
    value: {
      type: String,
      required: true
    },
    locales: {
      type: Array,
      required: true
    },
  },
  computed: {
    locale: {
      /**
       * Getter method for the locale
       * @return {String} 
       */
      get() {
        return this.value
      },

      /**
       * Setter method for the locale 
       * @param {String} locale 
       */
      set(locale) {
        this.$emit('input', locale)
      }
    },
    survey_locales() {
      return this.locales.map((l) => {
        return {
          text: l.toUpperCase(),
          value: l
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.language-selector {
  position: absolute;
  top: 0;
  right: 10px;
  width: 80px;

  @media (min-width: 560px) {
    position: fixed;
    width: 100px;
    right: 30px;
  }
}
</style>
