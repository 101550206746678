import Storage from '@/services/Storage'

export default {
  /**
   * Boot application and get survey data 
   * @param {} 
   */
  async boot ({ state, commit, dispatch }, ) {
    commit('setStatus', 'loading')
    try {
      // Get current date for multiple_answers_timestamp
      const now = new Date()
      //Get survey's data with given slugs
      const surveyData = await dispatch('survey/getSurveyData', null, { root: true })
      // Get transports
      const transports = await dispatch('transports/getTransports', null, { root: true })
      //Get submission with survey id
      let submissionData = await dispatch('submission/findOrCreate', surveyData.survey.id, { root: true })
      // Get static translations
      // let translations = await dispatch('translations/getTranslations', null, { root: true })

      if (submissionData) {
        //Set active page and history
        if (Storage.hasItem(`submission-${submissionData.submission.survey_id}`)) {
          const storageSubmission = Storage.getItem(`submission-${submissionData.submission.survey_id}`)
          if(!storageSubmission.updated_at ||storageSubmission.updated_at != surveyData.survey.updated_at){
            Storage.removeItem(`submission-${submissionData.submission.survey_id}`)
            submissionData = await dispatch('submission/findOrCreate', surveyData.survey.id, { root: true })
            dispatch('createStorage', { submissionData, surveyData }, { root: true })
            commit('filler/setActivePage', 'start', { root: true })
          }
        }
        if (Storage.hasItem(`submission-${submissionData.submission.survey_id}`)) {
          const submission = submissionData.submission
          const storageSubmission = Storage.getItem(`submission-${submissionData.submission.survey_id}`)
          if(!('multiple_answers_enabled' in storageSubmission) 
            && !('multiple_answers_interval' in storageSubmission) 
            && !('multiple_answers_reset_time' in storageSubmission)) {
            let updatedStorageData = storageSubmission
            updatedStorageData.updated_at = surveyData.survey.updated_at
            updatedStorageData.multiple_answers_enabled = surveyData.survey.multiple_answers_enabled
            updatedStorageData.multiple_answers_interval = surveyData.survey.multiple_answers_interval
            updatedStorageData.multiple_answers_reset_time = now.setHours(now.getHours() + parseInt(surveyData.survey.multiple_answers_interval))
            Storage.setItem(`submission-${submissionData.submission.survey_id}`, updatedStorageData)
          }

          if (storageSubmission.lottery_done) {
            commit('filler/setActivePage', 'end', { root: true })

            // Check if the survey can answered multiple times and the time interval has passed
            // Remove the old storage object
            // Create a new storage object with default values
            // Set start page to active page
            if(storageSubmission.multiple_answers_enabled 
              && storageSubmission.multiple_answers_reset_time 
              && storageSubmission.multiple_answers_reset_time <= now.getTime()) {
                Storage.removeItem(`submission-${submissionData.submission.survey_id}`)
                submissionData = await dispatch('submission/findOrCreate', surveyData.survey.id, { root: true })
                dispatch('createStorage', { submissionData, surveyData }, { root: true })
                commit('filler/setActivePage', 'start', { root: true })
            }
          } else if (submission.completed) {
            // Set timestamp if can answer multiple times
            commit('filler/setActivePage', 'lottery', { root: true })

            // Check if the survey can answered multiple times and the time interval has passed
            // Remove the old storage object
            // Create a new storage object with default values
            // Set start page to active page
            if(storageSubmission.multiple_answers_enabled 
              && storageSubmission.multiple_answers_reset_time 
              && storageSubmission.multiple_answers_reset_time <= now.getTime()) {
                Storage.removeItem(`submission-${submissionData.submission.survey_id}`)
                submissionData = await dispatch('submission/findOrCreate', surveyData.survey.id, { root: true })
                dispatch('createStorage', { submissionData, surveyData }, { root: true })
                commit('filler/setActivePage', 'start', { root: true })
            }
          } else {
            if (storageSubmission.survey_id === submission.survey_id) {
              const activePage = storageSubmission.activePage
              const history = storageSubmission.history ? storageSubmission.history : []
              dispatch('filler/continue', activePage, { root: true })
              dispatch('filler/setHistory', history, { root:true })
            }
          }
        } else {
          dispatch('createStorage', { submissionData, surveyData }, { root: true })
        }
      }
      commit('setStatus', 'ready')
    } catch (err) {
      if (state.status !== 'notFound') {
        commit('setStatus', 'error')
      }
      throw err
    }
  },

  createStorage({}, { submissionData, surveyData }) {
    const now = new Date()
    const submission = submissionData.submission
    Storage.setItem(`submission-${submissionData.submission.survey_id}`, {
      id: submission.id,
      updated_at : surveyData.survey.updated_at,
      activePage: null,
      survey_id: submission.survey_id,
      lottery_done: false,
      multiple_answers_enabled: surveyData.survey.multiple_answers_enabled,
      multiple_answers_interval: surveyData.survey.multiple_answers_interval,
      multiple_answers_reset_time: now.setHours(now.getHours() + parseInt(surveyData.survey.multiple_answers_interval))
    })
  }
}
