const storage = window.localStorage

export default {
  /**
   * @param {String} key
   */
  getItem (key) {
    return JSON.parse(storage.getItem(key))
  },

  /** 
   * @param {String} key
   */
  setItem (key, value) {
    storage.setItem(key,  JSON.stringify(value)) 
  },

  /** 
   * @param {String} key
   */
  removeItem (key) {
    storage.removeItem(key) 
  },

  /**
   *
   * @param {String} key
   * @return {Boolean} 
   */
  hasItem (key) {
    return storage.getItem(key) !== null
  }
}

