<template>
  <v-combobox
    v-model="country"
    :items="countries"
    item-text="name"
    :label="label"
    color="#000080"
    :error-messages="errorMessages"
    solo
  ></v-combobox>
</template>
<script>
import { mapGetters } from 'vuex'
import {translationMixins} from '@/mixins/translation-mixins'
export default {
  name: 'FillerCountry',
  mixins: [translationMixins],
  props: {
    value: String,
    invalid: Boolean,
    locale: String
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    label () {
      return this.staticTranslation('labels.country')
    },
    countries () {
      let countries = _.cloneDeep(this.$store.getters['country/all'])
      const finland = countries.find(country => country.name === 'Finland')
      if (finland) {
        const index = countries.indexOf(finland)
        countries.splice(index, 1)
        countries[0] = finland
      }
      return countries
    },
    /**
     * "item-value"" doesn't seem to work in this vuetify version's combobox" 
     */
    country: {
      /**
      * Getter method for the country
      * @return {String} 
      */
      get () {
        const country = this.countries.find(country => country.code === this.value)
        if(this.value != null && country != undefined){
          this.$emit('selectCountry', country.name);
        }
        return country != undefined ? country : this.value;
      },
    
      /**
      * Setter method for the country 
      * @param {String} country 
      */
      set (country) {
        if(country !== null && typeof country === 'object'){
          this.$emit('selectCountry', country.name)
          this.$emit('input', country.code)
        }else {
          this.$emit('selectCountry', country)
          this.$emit('input', country)
        }
      } 
    },
    errorMessages () {
      return this.invalid
        ? this.staticTranslation('messages.required') 
        : ''
    }
  },
  methods: {
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
  }
}
</script>
