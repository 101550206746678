<template>
  <v-app>
    <survey-filler-start-language-selector v-if="!languageSelected && surveyGroup !== null" :languages="groupLanguages"
        :hasThemeColor="hasThemeColor" :bgColor="bgColor" @languageSelected="selectGroupLanguage">
      </survey-filler-start-language-selector>
    <survey-group-screen v-if="surveyGroup !== null && languageSelected" :initialLanguage="groupLanguage" :surveyGroup="surveyGroup"></survey-group-screen>
    <filler-loading-screen v-if="status !== 'ready' && surveyGroup == null" :status="status"></filler-loading-screen>
    <template v-if="status == 'ready'">
      <survey-filler-start-language-selector v-if="!languageSelected && activePage === 'start'" :languages="languages"
        :hasThemeColor="hasThemeColor" :bgColor="bgColor" @languageSelected="selectLanguage">
      </survey-filler-start-language-selector>
      <survey-filler v-else :answerEditSource="'submission'" :lotteryEditSource="'lottery'" :activePage="activePage"
        :allSegments="segments" :activeSegment="activeSegment" :preview="false" :invalidFields="invalidFields"
        :locale="locale" :surveyData="surveyData" :surveyTargetData="surveyTarget" :transports="transports"
        :status="status" @start="start" @confirmAge="confirmAge" @next="next" @previous="previous" @cancel="cancel"
        @setValidField="setValidField" @validateSegmentFields="validateSegmentFields" @changeLocale="changeLocale"
        @participate="participate" @terms="terms" @privacy="privacy"></survey-filler>
    </template>
    <notifications></notifications>
  </v-app>
</template>

<script>
import SurveyFiller from '@/components/survey-filler/SurveyFiller'
import FillerLoadingScreen from '@/components/FillerLoadingScreen'
import SurveyGroupScreen from '@/components/SurveyGroupScreen'
import SurveyFillerStartLanguageSelector from "@/components/SurveyFillerStartLanguageSelector"
import Notifications from './components/Notifications'
import SurveyApi from '/src/services/api/filler/SurveyApi'

import { mapGetters } from 'vuex'
export default {
  name: 'SurveyApp',
  components: {
    SurveyFiller,
    SurveyFillerStartLanguageSelector,
    FillerLoadingScreen,
    SurveyGroupScreen,
    Notifications,
  },
  data() {
    return {
      languageSelected: false,
      groupLanguage: 'fi',
      surveyGroup: null,
    }
  },
  computed: {
    ...mapGetters('filler', {
      activePage: 'activePage',
      activeSegment: 'activeSegment',
      invalidFields: 'invalidFields',
      locale: 'locale'
    }),
    ...mapGetters('survey', {
      surveyData: 'data',
      surveyTarget: 'target',
      segments: 'segments'
    }),
    ...mapGetters('transports', {
      transports: 'transports'
    }),
    status() {
      return this.$store.getters['status']
    },
    languages() {
      if (!this.surveyData.hasOwnProperty('languages')
        || !this.surveyData.languages.length) {
        return ['fi', 'en']
      }

      return this.surveyData.languages.map(lang => lang.locale)
    },
    groupLanguages() {
      if (!this.surveyGroup.hasOwnProperty('languages')
        || !this.surveyGroup.languages.length) {
        return ['fi', 'en']
      }

      return this.surveyGroup.languages.map(lang => lang.locale)
    },
    hasThemeColor() {
      return this.surveyData.hasOwnProperty('theme_color')
        && this.surveyData.theme_color !== null
    },
    bgColor() {
      return this.hasThemeColor
        ? this.surveyData.theme_color
        : '#1abc9c'
    },
  },
  async created() {
    const pathname = window.location.pathname.split('/')
    if (pathname.length < 3) {
      const target = pathname[1]
      try {
        this.surveyGroup = await SurveyApi.getSurveyGroup(target)
      } catch (error) { }
    }
    await this.$store.dispatch('translations/getTranslations', null, { root: true })
    if (this.surveyGroup == null) {
      await this.$store.dispatch('boot')
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.locale != null) {
        this.selectLanguage(params.locale);
      }
    }
  },
  methods: {
    /**
     * Start getting answers for survey 
     */
    start() {
      this.$store.dispatch('filler/goToAgeConfirm')
    },
    confirmAge() {
      this.$store.dispatch('filler/startSurvey')
    },
    /**
     * Set next segment as active or stop getting answers 
     */
    next() {
      this.$store.dispatch('filler/next')
    },
    validateSegmentFields() {
      this.$store.dispatch('filler/validateSegmentFields')
    },
    /**
     * Set previous segment as active 
     */
    previous() {
      this.$store.dispatch('filler/previous')
    },
    cancel() {
      this.$store.dispatch('filler/cancel')
    },
    setValidField(field_id) {
      this.$store.commit('filler/removeFromInvalid', field_id)
    },
    changeLocale(locale) {
      this.$store.commit('filler/changeLocale', locale)
      this.$i18n.locale = locale
    },
    participate() {
      this.$store.dispatch('lottery/participate')
    },
    terms() {
      this.$store.commit('filler/setActivePage', 'terms')
    },
    privacy() {
      this.$store.commit('filler/setActivePage', 'privacy')
    },
    selectLanguage(language) {
      this.changeLocale(this.languages.includes(language) ? language : 'en')
      this.languageSelected = true
    },
    selectGroupLanguage(locale){
      this.groupLanguage = locale;
      this.languageSelected = true;
    },
  }
};
</script>
<style lang="scss">
.theme--light.v-input input,
.theme--light.v-input textarea,
.theme--light.v-input label {
  color: #000080 !important;
}
</style>
