<template>
  <div class="survey-filler-language-selector"
    :class="{'survey-filler-bg-image': !hasThemeColor}"
    :style="{backgroundColor: bgColor}">
    <h1 class="text-center mb-4">
      <span v-for="language in languages" class="d-block white--text" :key="language">{{ selectText(language) }}</span>
    </h1>

    <div class="language-list">
      <div class="language-item" v-for="language in languages" :key="language" @click="selectLanguage(language)">
        <country-flag 
          v-if="language === 'en'" 
          size="big"
          country="gb" 
         ></country-flag>
        <country-flag 
          v-else-if="language === 'sv'" 
          size="big"
          country="se" 
         ></country-flag>
        <country-flag 
          v-else
          size="big"
          :country="language" 
         ></country-flag>
        <span class="white--text">{{ language.toUpperCase() }}</span>
      </div>
    </div>

  </div>
</template>
<script>
import CountryFlag from 'vue-country-flag'
import { translationMixins } from '@/mixins/translation-mixins'
import { mapGetters } from 'vuex'

export default {
  name: 'SurveyFillerStartLanguageSelector',
  components: {
    CountryFlag
  },
  mixins: [translationMixins],
  props: {
    languages: {
      type: Array,
      required: true,
    },
    bgColor: {
      type: String,
      required: true,
    },
    hasThemeColor: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
  },
  methods: {
    selectLanguage (language) {
      this.$emit('languageSelected', language);
    },
    selectText (language) {
      return this.getStaticTranslation(language, 'texts.selectLanguage', this.translations, null)
    }
  },
}
</script>
<style lang="scss">
.survey-filler-language-selector {
  font-family: 'Josefin Sans', sans-serif !important;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .language-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .language-item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 1rem;

      span {
        margin-top: 0.5rem;
      }
    }
  }
}
</style>
