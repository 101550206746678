export default {
	/**
	 * Set segment as active segment
	 * @param {Object} segment 
	 */
	setActiveSegment(state, segment) {
		state.activeSegment = segment
	},
	/**
	 * Set active page
	 * @param {String} page 
	 */
	setActivePage(state, page) {
		state.activePage = page
	},
	/**
	 * Set history if survey is continued
	 * @param {Array} history 
	 */
	setHistory(state, history) {
		state.history = history
	},
	/**
	 * Add to history
	 * @param {String} segment_id
	 */
	addToHistory(state, segment_id) {
		state.history.push(segment_id)	
	},
	/**
	 * Remove last segment from history
	 * @param {}  payload 
	 */
	removeLastFromHistory(state,  payload) {
		state.history.pop()	
	},
	/**
	 * Clear segment history
	 */
	clearHistory(state) {
		state.history = []	
	},
	/**
	 * Add field to invalid list
	 * @param {String} field_id 
	 */
	addFieldToInvalid(state, field_id) {
		const index = state.invalidFields.indexOf(field_id)		
		if (index === -1) {
			state.invalidFields.push(field_id)	
		}
	},
	/**
	 * Remove field from invalid list
	 * @param {String} field_id 
	 */
	removeFromInvalid(state, field_id) {
		const index = state.invalidFields.indexOf(field_id)
		if (index > -1) {
			state.invalidFields.splice(index, 1)
		}
	},
	/**
	 * Clear invalid fields list
	 */
	clearInvalid(state) {
		state.invalidFields = []	
	},
	/**
	 * Change locale
	 * @param {String} locale 
	 */
	changeLocale(state, locale) {
		state.locale = locale
	},
	/**
	 * Set oldNextSegment. Is used when we want to remove
	 * answers between segment. This happens if user goes back
	 * and changes answers and we have a new jump route
	 * @param {Object} segment 
	 */
	setOldNextSegment(state, segment) {
		state.oldNextSegment = _.cloneDeep(segment)	
	},
}
