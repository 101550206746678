import survey from './survey'
import filler from './filler'
import submission from './submission'
import notification from '@/store/shared-modules/notification'
import lottery from './lottery'
import country from '@/store/shared-modules/country'
import transports from './transports'
import translations from './translations'

export default {
	survey: survey,
	filler: filler,
	submission: submission,
	country: country,
	lottery: lottery,
	notification: notification,
	transports: transports,
	translations: translations,
}
