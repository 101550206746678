<template>
  <div
    :style="{ 'backgroundColor': surveyGroup.group_color != null ? surveyGroup.group_color : '#FFFF', 'min-height': '100vh' }">
    <div class="survey-filler-header d-flex justify-center">
      <div class="header-box">
        <img class="header-box-image" src="@/assets/salmi_logo.png" alt="">
      </div>
      <survey-filler-language-selector v-model="language" :locales="getLocales()"></survey-filler-language-selector>
    </div>
    <h1 :style="{ 'text-align': 'center' ,'margin' : '20px' }">{{ getSurveyGroupText() }}</h1>
    <div>
      <div :style="{ 'marginTop': '75px', 'text-align-last': 'center' }">
        <div v-for="survey in surveyGroup.surveys" :key="survey.id">
          <v-card class="filler-option-box white py-4 px-4 d-inline-flex justify-center align-center text-center"
            @click="goToSurvey(survey)" tile>
            {{ getSurveyButtonText(survey) }}</v-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SurveyFillerLanguageSelector from '../components/survey-filler/SurveyFillerLanguageSelector'
export default {
  name: 'SurveyGroupScreen',
  components: {
    SurveyFillerLanguageSelector,
  },
  props: {
    surveyGroup: Object,
    initialLanguage: String
  },

  data() {
    return {
      language: null,
    }
  },
  created() {
    this.language = this.initialLanguage ?? this.surveyGroup.languages[0].locale;
  },
  methods: {
    goToSurvey(survey) {
      window.location.href = survey.surveySlug + "?locale=" + this.language;
    },
    getSurveyButtonText(survey) {
      let obj = this.surveyGroup.translations.find((object) => {
        if (object.survey_id === survey.id && object.locale === this.language) {
          return object;
        }
      });
      return obj.title;
    },
    getSurveyGroupText() {
      let obj = this.surveyGroup.texts.find((o) => {
        if (o.locale === this.language) {
          return o.title;
        }
      });
      return obj.title;
    },
    getLocales() {
      let locales = [];
      this.surveyGroup.languages.forEach(language => {
        locales.push(language.locale);
      })
      return locales;
    }
  }
}


</script>
<style lang="scss" scoped>
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.topped {
  position: fixed;
  margin-top: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.filler-option-box {
  width: 300px;
  margin: 10px;
}
</style>
