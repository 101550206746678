<template>
  <v-card
    class="filler-option-box white py-4 px-4 d-inline-flex justify-center align-center text-center"
    :class="[
      {'light-blue': isChosen},
      {'white--text': isChosen}
    ]"
    @click="choose"
    tile
  >
    {{label}}
  </v-card>
</template>
<script>
export default {
  name: 'FillerOptionBox',
  props: {
    option: {
      type: Object,
      required: true
    },
    isChosen: {
      type: Boolean
    },
    locale: String
  },
  computed: {
    label () {
      const optionTranslation = this.option.translations.find(translation => translation.locale === this.locale)
      return optionTranslation ? optionTranslation.label : ''
    }
  },
  methods: {
    choose () {
      this.$emit('choose', this.option)
    }
  }
}
</script>
<style lang="scss" scoped>
.filler-option-box {
  width: calc(50% - 3px);
  margin-bottom: 6px;
}

</style>
