import axios from 'axios'
import store from '@/store/survey-filler'

const http = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	withCredentials: true
})

export default http

//http.interceptors.response.use(function (response) {
//    return response;
//  }, function (error) {       
//    console.log(error)
//		if (error.response.status === 404) {
//		  console.log(error)
//			store.commit('setStatus', 'notFound')
//		} else if (error.response.status === 403) {
//			store.commit('setStatus', 'notFound')
//		} else if (error.response.status === 422) {
//			if (error.response.data.hasOwnProperty('errors')) {
//				const errors = error.response.data.errors
//				if (
//					errors.hasOwnProperty('email')
//					&& errors.email.includes('taken')
//				) {
//					store.dispatch('notification/showNotifications', [
//						{
//							type: 'error', 
//							text: 'Sähköpostilla on jo ilmoittauduttu'
//						}
//					])
//				}
//			} 
//		}
//  });
