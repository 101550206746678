import TransportApi from '@/services/api/TransportApi'

export default {
	/**
	 * description 
	 * @param {} 
	 */
	async getTransports ({ commit, dispatch }) {
			const transports = await TransportApi.all()
			dispatch('setTransports', transports)
			return transports
	},
	/**
	 * Normalize survey's data and set to state 
	 * @param {Object} survey
	 */
	setTransports ({ commit, dispatch }, data) {
		//let segments = {}, fields = {}, options = {}
			const transports = _.cloneDeep(data)
			commit('setTransports', transports)
	},
}
