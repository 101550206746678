<template>
  <div class="confirmation-page mt-6 px-5 px-sm-1 white--text"
        v-html="staticTranslation('texts.ageConfirmation')">
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {translationMixins} from '@/mixins/translation-mixins'
export default {
  name: 'SurveyFillerAgeConfirmation',
  mixins: [translationMixins],
  props: {
    locale: {
      type: String,
      default: 'fi',
    }
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
  },
  methods: {
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
  },
}
</script>
<style lang="scss" scoped>
.confirmation-page {
  font-family: 'Josefin Sans', sans-serif !important;
}
</style>
