<template>
  <div class="map-passage-list">
    <span class="list-title" v-if="isOneWay">{{ staticTranslation('labels.oneWay') }}</span>
    <span class="list-title" v-if="passages.length > 0 && !isOneWay">{{ staticTranslation('labels.wayTo') }}</span>
    <map-passage-list-card v-for="(passage, index) in wayTo" :key="index" :passage="passage" :index="index"
      :locale="locale" v-on:remove-passage="removePassage" v-on:edit-passage="editPassage"></map-passage-list-card>
    <span class="list-title" v-if="!isOneWay && (hasWayBack || this.fillDestinations)">{{
    staticTranslation('labels.wayBack') }}</span>
    <map-passage-list-card v-for="(passage, index) in wayBack" :key="wayTo.length + index" :passage="passage"
      :index="wayTo.length + index" :locale="locale" v-on:remove-passage="removePassage"
      v-on:edit-passage="editPassage"></map-passage-list-card>
  </div>
</template>

<script>
import MapPassageListCard from '@/components/MapPassageListCard'
import { mapGetters } from 'vuex'
import { translationMixins } from '@/mixins/translation-mixins'

export default {
  name: 'MapPassageList',
  mixins: [translationMixins],
  components: {
    MapPassageListCard,
  },
  data() {
    return {}
  },
  props: {
    locale: String,
    passages: {
      type: Array,
      required: true,
    },
    fillDestinations: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    isOneWay: {
      type: Boolean,
      required: true,
    }
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    wayTo() {
      return this.passages.filter(passage => {
        return !passage.isWayBack
      })
    },
    wayBack() {
      return this.passages.filter(passage => {
        return passage.isWayBack
      })
    },
    hasWayBack() {
      return this.wayBack.length > 0
    }
  },
  methods: {
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
    removePassage(passage, index) {
      this.$emit('remove-passage', passage, index)
    },
    editPassage(passage, index) {
      this.$emit('edit-passage', passage, index)
    }
  },
}

</script>

<style lang="scss" scoped>
.map-passage-list {
  overflow-y: auto;
  max-height: 15vh;

  .list-title {
    display: flex;
    width: 100%;
    color: #A9A9A9;
    position: relative;
    align-items: center;
    margin: 10px 0 0;

    &::after {
      content: "";
      display: block;
      flex: 1;
      height: 2px;
      background-color: #ddd;
      margin-left: 10px;
    }
  }
}
</style>

