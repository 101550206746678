import Http from '@/services/SurveyHttp'

export default {
	/**
	 * Post new lottery participation
	 *
	 * @param {Object} data
	 */
	async participate (data) {
		try {
			const response = await Http.post('api/lotteries/participate', data)
			return response.data
		} catch (err) {
			throw err
		}
	},
}
