<template>
  <div class="segment-filler-field mb-2">
    <div class="field-label white--text text-center py-4">{{ this.answerIsDone ? "" : label }}</div>
    <div v-if="field.answer_type === 'multiselect'" class="white--text text-center mb-2">
      {{ staticTranslation('texts.chooseOneOrMany') }}</div>
    <filler-options v-if="field.answer_type === 'select' || field.answer_type === 'multiselect'" v-model="answerValue"
      :field="field" :answer="answer" :answerEditSource="answerEditSource" :itemsSource="itemsSource" :locale="locale"
      :segment="segment" :preview="preview" :invalid="invalid" @setValid="setValid" @nextBySelect="nextBySelect">
    </filler-options>
    <filler-text v-else-if="field.answer_type === 'input'" v-model="answerValue" :invalid="invalid" :locale="locale">
    </filler-text>
    <filler-date v-else-if="field.answer_type === 'date'" v-model="answerValue" :invalid="invalid" :locale="locale" :field="field">
    </filler-date>
    <filler-year v-else-if="field.answer_type === 'year'" v-model="answerValue" :invalid="invalid" :locale="locale">
    </filler-year>
    <filler-country v-else-if="field.answer_type === 'country'" v-model="answerValue" :invalid="invalid"
      :locale="locale" @selectCountry="selectCountry"></filler-country>
    <filler-city v-else-if="field.answer_type === 'city'" v-model="answerValue" :country="selectedCountry" :invalid="invalid" :locale="locale">
    </filler-city>
    <filler-slider v-else-if="field.answer_type === 'slider'" v-model="answerValue" :activated="answerValue != null ? true : false" :field="field" :invalid="invalid"
      :locale="locale" class="mt-5"></filler-slider>
    <filler-map v-else-if="field.answer_type === 'map'" :doneValue="answerIsDone" v-model="answerPassages"
      v-on:update-value-prop="updateValue" v-on:update-finished-state="updateFinishedState"
      :answer-edit-source="answerEditSource" :field="field" :invalid="invalid" :locale="locale" :transports="transports"
      :bgColor="bgColor" class="mt-2"></filler-map>
  </div>
</template>
<script>
import FillerOptions from '@/components/filler-answer-components/FillerOptions'
import FillerText from '@/components/filler-answer-components/FillerText'
import FillerDate from '@/components/filler-answer-components/FillerDate'
import FillerYear from '@/components/filler-answer-components/FillerYear'
import FillerCountry from '@/components/filler-answer-components/FillerCountry'
import FillerCity from '@/components/filler-answer-components/FillerCity'
import FillerSlider from '@/components/filler-answer-components/FillerSlider'
import FillerMap from '@/components/filler-answer-components/FillerMap'

import { mapGetters } from 'vuex'
import { translationMixins } from '@/mixins/translation-mixins'

export default {
  name: 'SurveyFillerField',
  mixins: [translationMixins],
  components: {
    FillerOptions,
    FillerText,
    FillerDate,
    FillerYear,
    FillerCountry,
    FillerCity,
    FillerSlider,
    FillerMap
  },
  props: {
    bgColor: {
      type: String
    },
    field: {
      type: Object,
      required: true
    },
    segment: {
      type: Object,
      required: true
    },
    answerEditSource: {
      type: String,
      required: true
    },
    itemsSource: {
      type: String,
    },
    locale: String,
    preview: {
      type: Boolean,
      required: true
    },
    invalidFields: Array,
    transports: {
      type: Array
    },
    selectedCountry : String,
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    answer() {
      return this.$store.getters[`${this.answerEditSource}/getAnswer`](this.field.id)
    },
    label() {
      const fieldTranslation = this.field.translations.find(translation => translation.locale === this.locale)
      return fieldTranslation
        ? fieldTranslation.label
        : ''
      /*return this.field.translations[this.locale].label*/
    },
    /**
     * Check if fields' answers are invalid
     *
     * @return {Boolean} 
     */
    invalid() {
      return this.invalidFields.includes(this.field.id)
    },
    answerValue: {
      /**
       * Getter method for the answerValue
       * @return {String} 
       */
      get() {
        return this.answer.value
      },

      /**
       * Setter method for the answerValue 
       * @param {String} answerValue 
       */
      set(answerValue) {
        this.$store.commit(`${this.answerEditSource}/editValue`, {
          field_id: this.field.id,
          value: answerValue
        })
        if (
          this.invalid
          && (answerValue !== '' && answerValue !== null)
        ) {
          this.setValid()
        }
      }
    },
    answerIsDone: {
      /**
       * Getter method for the answerValue
       * @return {String} 
       */
      get() {
        return this.answer.is_done
      },

      /**
       * Setter method for the answerValue 
       * @param {String} answerIsDone 
       */
      set(answerIsDone) {
        this.$store.commit(`${this.answerEditSource}/editIsDone`, {
          field_id: this.field.id,
          value: answerIsDone
        })
        if (
          this.invalid
          && (answerIsDone !== '' && answerIsDone !== null)
        ) {
          this.setValid()
        }
      }
    },
    answerPassages: {
      /**
       * Getter method for map field passages
       * @return {Array}
       */
      get() {
        return this.answer.passages
      },

      /**
       *
       *
       */
      set(answerPassages) {
        this.$store.commit(`${this.answerEditSource}/editPassages`, {
          field_id: this.field_id,
          passages: answerPassages
        })
        if (this.invalid && (answerPassages !== null)) {
          this.setValid()
        }
      }
    },
  },
  methods: {
    selectCountry(data){
      this.$emit('selectedCountry',data);
    },
    updateValue(val) {
      this.answer.passages = val
    },
    updateFinishedState(val) {
      this.answerIsDone = val;
    },
    setValid() {
      this.$emit('setValid', this.field.id)
    },
    nextBySelect(data) {
      this.$emit('nextBySelect', data)
    },
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
  }
}
</script>
<style lang="scss" scoped>
.segment-filler-field {

  .field-label {
    font-size: 20px;

    @media (min-width: 768px) {
      font-size: 25px;
    }

    font-family: 'Josefin Sans',
    sans-serif !important;
  }
}
</style>
