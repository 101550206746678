<template>
  <div class="survey-filler-segment mt-4 mb-10">
    <survey-filler-field
      v-for="field in segmentFields"
      :key="field.id"
      :field="field"
      :answerEditSource="answerEditSource"
      :itemsSource="itemsSource"
      :locale="locale"
      :segment="segment"
      :preview="preview"
      :transports="transports"
      :invalidFields="invalidFields"
      :bgColor="bgColor"
      :selectedCountry="country"
      @setValid="setValidField"
      @nextBySelect="nextBySelect"
      @selectedCountry="selectCountry"
    ></survey-filler-field>
  </div>
</template>
<script>
import SurveyFillerField from './SurveyFillerField'
export default {
  name: 'SurveyFillerSegment',
  components: {
    SurveyFillerField
  },
  props: {
    bgColor:{
      type:String
    },
    segment: {
      type: Object,
      required: true
    },
    answerEditSource: {
      type: String,
      required: true
    },
    itemsSource: {
      type: String,
    },
    locale: String,
    preview: {
      type: Boolean,
      required: true
    },
    invalidFields: Array,
    transports: {
      type: Array
    }
  },
  data() {
    return {
      country: "",
    }
  },
  computed: {
    segmentFields () {
      return this.segment.fields
      if (this.preview) {
        if (this.segment.forced) {
          return this.$store.getters['surveySection/segmentFields'](this.segment.fields)
        } else if (this.segment.is_common) {
          return this.$store.getters['commonSegment/field/segmentFields'](this.segment.fields)
        } else {
          return this.$store.getters[`${this.itemsSource}/field/segmentFields`](this.segment.fields)
        }
      } else {
         return this.$store.getters[`survey/segmentFields`](this.segment.fields)
      }
    }
  },
  methods: {
    selectCountry(data){
      this.country = data
    },
    setValidField (field_id) {
      this.$emit('setValidField', field_id)
    },
    nextBySelect (data) {
      this.$emit('nextBySelect', data)
    }
  }
}
</script>
