import Http from '@/services/Http'

export default {
  async all () {
    try {
      const response = await Http.get('api/transports') 

      // Normalize
      const translated = response.data.map(transport => {
        let obj = {...transport}
        let translations = {
          en: {
            name: null
          },
          fi: {
            name: null
          },
        }


        transport.translations.forEach(translation => {
          translations = {
            ...translations,
            [translation.locale]: {
              name: translation.name
            }
          }
        })

        let options = transport.options.map(option => {
          let option_translations = {}

          option.translations.forEach(translation => {
            option_translations = {
              ...option_translations,
              [translation.locale]: {
                name: translation.name
              }
            }
          })

          return {
            ...option,
            translations: option_translations
          }
        })


        return {
          ...obj,
          translations: translations,
          options: options
        }
      })
    
      return translated
    } catch (err) {
      throw err
    }
  },
}
