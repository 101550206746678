import mixinMutations from '@/store/mixins/survey/showing/mutations'
import mixinGetters from '@/store/mixins/survey/showing/getters'
import actions from './actions'

export default {
	namespaced: true,
	state: {
		segments: [],
		jumps: [],
		data: {},
		target: {}
	},
	getters: {
		//...mixinGetters,
		isLastSegment: state => segment => {
			return segment.sort_order === state.segments.length
		},
		segments: state => state.segments,
		segmentJumps: state => segment_id => {
			return state.jumps.filter(jump => {
				return jump.from_segment_id === segment_id
			})
		},
		getSegment: state => segment_id => {
			const foundSegment = state.segments.find(segment => segment.id === segment_id)
			return foundSegment ? foundSegment : null
		}, 
		getNextSegment: state => activeSegment => {
			const nextSegment = state.segments.find(segment => segment.sort_order === activeSegment.sort_order + 1)
			return nextSegment ? nextSegment : null
		}, 
		data: state => state.data,
		target: state => state.target
  },
  mutations: {
		...mixinMutations,
		...{
			/**
			 * Set segments with field and options
			 * @param {Array} segments 
			 */
			setNestedSegments(state, segments) {
				state.segments = segments
			},
			/**
			 * Set base with translations object
			 * @param {Object} data 
			 */
			setData(state, data) {
				state.data = data
				if (data.hasOwnProperty('translations') && data.translations.length > 0) {
					let translations = {}
					data.translations.forEach(translation => {
						translations = {
							...translations,
							[translation.locale]: {
								start_text: translation.start_text,
								end_text: translation.end_text
							}
						}	
					})
					state.data = {
						...data,
						translations: translations
					}
				} else {
					state.data = {
						...data,
						translations: {
							fi: {},
							en: {}
						}
					}
				}
			},	
			/**
			 * Set targets data
			 * @param {Object} target 
			 */
			setTarget(state, target) {
				state.target = target
			},
		},
  },
  actions: actions
}
