<template>
  <v-footer
    fixed
    color="transparent"
    class="pb-8 survey-filler-footer"
  >
    <div class="survey-filler-container">
      <div 
        class="d-flex align-center"
        :class="[
          {'justify-center': center},
          {'justify-end': activePage === 'segment' && showNext && !showPrevious},
          {'justify-space-between': spaceBetween}
        ]"
      >
        <v-btn 
          class="filler-button"
          v-if="showPrevious"
          color="white"
          large
          :x-large="$vuetify.breakpoint.width > 350"
          outlined
          @click="previous"
        >{{staticTranslation('buttons.previous')}}
        </v-btn>
        <v-btn 
          class="filler-button"
          v-if="activePage === 'start' && showStart"
          color="white"
          x-large
          outlined
          @click="terms"
        >{{staticTranslation('buttons.terms')}}
        </v-btn>
        <v-btn 
          class="filler-button"
          v-if="activePage === 'lottery' && showStart"
          color="white"
          x-large
          outlined
          @click="privacy"
        >{{staticTranslation('buttons.privacy')}}
        </v-btn>
        <v-btn 
          class="filler-button"
          v-if="activePage === 'segment'"
          color="white"
          large
          :x-large="$vuetify.breakpoint.width > 350"
          outlined
          @click="next"
        >{{staticTranslation('buttons.next')}}</v-btn>
        <v-btn 
          class="filler-button"
          v-if="activePage === 'start'"
          color="white"
          x-large
          outlined
          @click="start"
        >{{staticTranslation('buttons.start')}}</v-btn>
        <v-btn 
          class="filler-button"
          v-if="activePage === 'age-confirmation'"
          color="white"
          x-large
          outlined
          @click="cancel"
        >{{staticTranslation('buttons.cancel')}}</v-btn>
        <v-btn 
          class="filler-button"
          v-if="activePage === 'age-confirmation'"
          color="white"
          x-large
          outlined
          @click="confirmAge"
        >{{staticTranslation('buttons.continue')}}</v-btn>
        <v-btn 
          class="filler-button"
          v-if="activePage === 'lottery'"
          color="white"
          x-large
          outlined
          @click="participate"
        >{{staticTranslation('buttons.participate')}}</v-btn>
      </div>
    </div>
  </v-footer>
  
</template>
<script>
import { mapGetters } from 'vuex'
import {translationMixins} from '@/mixins/translation-mixins'
export default {
  name: 'SurveyFillerFooter',
  mixins: [translationMixins],
  props: {
     imgSource: {
      type: String,
      required: true
    },
    activePage: {
      type: String
    },
    activeSegment: {
      required: true
    },
    allSegments: {
      type: Array
    },
    locale: {
      type: String,
      default: 'fi'
    },
    canNext: Boolean,
    showStart: Boolean,
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    center () {
      return this.activePage === 'terms' 
        || this.activePage === 'privacy' 
        || (!this.showStart && this.activePage === 'start')
    },
    spaceBetween() {
      return (
        (this.activePage === 'segment' && this.showNext && this.showPrevious) 
        || this.activePage === 'age-confirmation'
        || (this.activePage === 'start' && this.showStart)
        || this.activePage === 'lottery'
      )
    },
    showPrevious () {
      const first = this.allSegments[0]
      return (this.activePage === 'segment'
        && this.activeSegment
        && first.id !== this.activeSegment.id)
        || this.activePage === 'terms'
        || this.activePage === 'privacy'
    },
    showNext () {
      return true
      //TODO
      const last = this.allSegments[this.allSegments.length - 1]
      return this.activePage === 'segment'
        && this.activeSegment
        && last.id !== this.activeSegment.id
    }
  },
  methods: {
    next () {
      if(this.canNext){
        this.$emit('next')
      }
    },
    previous () {
      this.$emit('previous')
    },
    start () {
      this.$emit('start')
    },
    confirmAge () {
      this.$emit('confirmAge')
    },
    cancel () {
      this.$emit('cancel')
    },
    participate () {
      this.$emit('participate')
    },
    terms () {
      this.$emit('terms')
    },
    privacy () {
      this.$emit('privacy')
    },
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
  }
}
</script>
<style lang="scss" scoped>
.survey-filler-footer {

  .filler-button {
    min-width: 142px;
    font-size: 0.9rem;
  }
}

.v-btn {
  font-family: 'Josefin Sans', sans-serif !important;
}
</style>
