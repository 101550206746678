<template>
  <div 
    class="survey-filler"
    :class="{'survey-filler-bg-image': !hasThemeColor}"
    :style="{backgroundColor: bgColor}"
  >
    <div class="survey-filler-content">
      <div class="survey-filler-container">
        <survey-filler-header
          :locale="locale"
          :locales="locales"
          :imgSource="imgSource"
          @changeLocale="changeLocale"
        ></survey-filler-header>
        <survey-filler-start
          v-if="activePage === 'start' && showStart"
          :locale="locale"
          :survey="surveyData"
          :surveyTarget="surveyTargetData"
          :imgSource="footerImgSource"
        ></survey-filler-start>
        <survey-filler-end
          v-else-if="activePage === 'end' && showStart"
          :locale="locale"
          :imgSource="finishImgSource"
          :survey="surveyData"
        ></survey-filler-end> <survey-filler-age-confirmation
          v-else-if="activePage === 'age-confirmation' && showStart"
          :locale="locale"
        ></survey-filler-age-confirmation>
        <survey-filler-terms
          v-else-if="activePage === 'terms' && showStart"
          :locale="locale"
        ></survey-filler-terms>
        <survey-filler-lottery-terms
          v-else-if="activePage == 'privacy' && showStart"
          :locale="locale"
        ></survey-filler-lottery-terms>
        <survey-filler-lottery
          v-else-if="activePage === 'lottery' && showStart"
          :lotteryEditSource="lotteryEditSource"
          :locale="locale"
          :survey="surveyData"
        ></survey-filler-lottery>
        <span 
          v-else-if="activePage === 'end' && !showStart"
          class="px-4 mt-8 d-block"
        >
          Loppu
        </span>
        <div 
          v-else-if="activePage === 'segment'"
          class="survey-filler-body px-3"
        >
          <transition
            :enter-active-class="enterClass"
            :leave-active-class="leaveClass"
          >
            <survey-filler-segment
              v-show="show && activeSegment"
              :segment="activeSegment"
              :answerEditSource="answerEditSource"
              :itemsSource="itemsSource"
              :locale="locale"
              :preview="preview"
              :transports="transports"
              :bgColor="bgColor"
              :invalidFields="invalidFields"
              @setValidField="setValidField"
              @nextBySelect="nextBySelect"
            >
            </survey-filler-segment>
          </transition>
          <div v-if="footerImgSource" class="survey-filler-header">
            <div class="header-box d-flex justify-center">
              <img 
                class="header-box-image"
                :src="footerImgSource" 
                alt="">
            </div>
          </div>  
        </div>
      </div>
    </div>
    <survey-filler-footer
      @next="next"
      @previous="previous"
      @start="start"
      @cancel="cancel"
      @confirmAge="confirmAge"
      @participate="participate"
      @terms="terms"
      @privacy="privacy"
      :activePage="activePage"
      :activeSegment="activeSegment"
      :allSegments="allSegments"
      :showStart="showStart"
      :locale="locale"
      :imgSource="''"
      :canNext="show"
    ></survey-filler-footer>
  </div>
</template>
<script>

import SurveyFillerStart from './SurveyFillerStart'
import SurveyFillerEnd from './SurveyFillerEnd'
import SurveyFillerAgeConfirmation from './SurveyFillerAgeConfirmation'
import SurveyFillerLottery from './SurveyFillerLottery'
import SurveyFillerSegment from './SurveyFillerSegment'
import SurveyFillerFooter from './SurveyFillerFooter'
import SurveyFillerHeader from './SurveyFillerHeader'
import SurveyFillerTerms from './SurveyFillerTerms'
import SurveyFillerLotteryTerms from './SurveyFillerLotteryTerms'

export default {
  name: 'SurveyFiller',
  components: {
    SurveyFillerFooter,
    SurveyFillerHeader,
    SurveyFillerSegment,
    SurveyFillerStart,
    SurveyFillerEnd,
    SurveyFillerAgeConfirmation,
    SurveyFillerLottery,
    SurveyFillerTerms,
    SurveyFillerLotteryTerms
  },
  props: {
  showStart: {
    type: Boolean,
      default: true
    },
    activeSegment: {
      type: Object
    },
    allSegments: {
      type: Array
    },
    activePage: {
      type: String,
      default: 'start'
    },
    answerEditSource: {
      type: String,
      required: true
    },
    lotteryEditSource: {
      type: String,
    },
    itemsSource: {
      type: String,
    },
    locale: {
      type: String,
      default: 'fi'
    },
    preview: {
      type: Boolean,
      required: true,
      default: false
    },
    invalidFields: {
      type: Array,
      required: true
    },
    invalidLotteryFields: {
      type: Array
    },
    surveyData: {
      type: Object
    },
    surveyTargetData: {
      type: [Object, null],
      default () {
        return null
      }
    },
    status: {
      type: String,
      default: 'ready'
    },
    transports: {
      type: Array
    }
  },
  watch: {
    activeSegment: function() {
          this.show = true;
    },
  },
  data () {
    return {
      show: true,
      directionRight: true,
    }
  },
  computed: {
    imgSource () {
      if (this.preview) {
        if (this.surveyData.image_preview_path) {
          return `${process.env.VUE_APP_API_URL}${this.surveyData.image_preview_path}` 
        } else if (this.surveyData.image_path) {
          return `${process.env.VUE_APP_API_URL}${this.surveyData.image_path}` 
        } else {
          return require('@/assets/salmi_logo.png')
        }
      } else {
        if (this.surveyData.image_path) {
          return `${process.env.VUE_APP_API_URL}${this.surveyData.image_path}` 
        } else {
          return require('@/assets/salmi_logo.png')
        }
      }
    },
    footerImgSource () {
      if (this.surveyData.bottom_image_path) {
        return `${process.env.VUE_APP_API_URL}${this.surveyData.bottom_image_path}` 
      } else {
        return '';
      }
    },
    finishImgSource () {
      if (this.surveyData.finish_image_path) {
        return `${process.env.VUE_APP_API_URL}${this.surveyData.finish_image_path}` 
      } else {
        return '';
      }
    },
    enterClass () {
      return this.directionRight
        ? 'animate__animated animate__fadeInRight animate__fast'
        : 'animate__animated animate__fadeInLeft animate__fast'
    },
    leaveClass () {
      return this.directionRight
        ? 'animate__animated animate__fadeOutLeft animate__faster'
        : 'animate__animated animate__fadeOutRight animate__faster'
    },
    hasThemeColor () {
      return this.surveyData.hasOwnProperty('theme_color') 
        && this.surveyData.theme_color !== null
    },
    bgColor () {
      return this.hasThemeColor
        ? this.surveyData.theme_color
        : '#1abc9c'
    },
    locales () {
      if(!this.surveyData.languages?.length) return [ 'fi', 'en' ]
      return this.surveyData.languages.map(lang => lang.locale)
    },
  },
  created () {
    this.$store.dispatch('country/fetchAll')
  },
  methods: {
    next () {
      this.$emit('validateSegmentFields')
      if (this.invalidFields.length === 0) {
        this.show = false
        this.directionRight = true
        // setTimeout(() => {
        this.$emit('next')
          // this.show = true
        // }, 200)
      }
    },
    nextBySelect (data) {
      if (
        this.activeSegment.fields.length === 1
        && !data.option.asks_value
      ) {
        this.next()
      }
    },
    previous () {
      this.show = false
      this.directionRight = false
      // setTimeout(() => {
      this.$emit('previous')
        // this.show = true
      // }, 200)
    },
    start () {
      this.$emit('start')
    },
    submit () {
      this.$emit('submit')
    },
    setValidField (field_id) {
      this.$emit('setValidField', field_id)
    },
    changeLocale (locale) {
      this.$emit('changeLocale', locale)
    },
    cancel () {
      this.$emit('cancel')
    },
    confirmAge () {
      this.$emit('confirmAge')
    },
    editLottery (data) {
      this.$emit('editLottery', )
    },
    participate () {
      this.$emit('participate')
    },
    terms () {
      this.$emit('terms')
    },
    privacy () {
      this.$emit('privacy')
    }
  }
}
</script>
<style lang="scss">
.survey-filler {
  font-family: 'Josefin Sans', sans-serif !important;
  height: 100vh;

  .survey-filler-content {
    height: calc(100vh - 85px);
    overflow-y: auto;
  }

  .survey-filler-container {
    width: 100%;

    @media (min-width: 500px) {
      width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.survey-filler-bg-image {
  background-image: linear-gradient(to bottom right, #fdcb6e, green);
}
.survey-filler-header {
  position: relative;
}

.header-box {
  .header-box-image {
    padding-top: 10px;
    width: 150px;
    height: auto;

    @media (min-width: 560px) {
      top: 75px;
      width: 200px;
      height: auto;
    }
  }
}
</style>
