<template>
  <v-combobox
    v-model="city"
    :items="cities"
    :label="label"
    :error-messages="errorMessages"
    solo
    color="#000080"
  ></v-combobox>
</template>
<script>
// import finlandCities from '@/services/FinlandCities'
import { mapGetters } from 'vuex'
import {translationMixins} from '@/mixins/translation-mixins'

export default {
  name: 'FillerCity',
  mixins: [translationMixins],
  props: {
    value: String,
    invalid: Boolean,
    country: String,
    locale: {
      type: String,
      default: 'fi'
    },
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    label () {
      return this.staticTranslation('labels.city')
    },
    cities () {
      let cities = [];
      let countries = _.cloneDeep(this.$store.getters['country/all'])
      const result = countries.find(({ name }) => name === this.country);
      cities = result?.cities;
      // if(cities != undefined){
      //   if(!cities?.includes(this.city)){
      //     this.city = "";
      //   }
      // }
      return cities;
    },
    city: {
      /**
      * Getter method for the city
      * @return {String} 
      */
      get () {
        // const cities = this.cities();
        // cities.find(city => city === this.value)
        return this.value; 
      },
      /**
      * Setter method for the city 
      * @param {String} city 
      */
      set (city) {
        this.$emit('input', city)
      } 
    },
    errorMessages () {
      return this.invalid
        ? this.staticTranslation('messages.required')
        : ''
    }
  },
  methods: {
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
  },
}
</script>
