import Http from '@/services/SurveyHttp'

export default {
  async getSurvey (targetSlug, surveySlug) {
    try {
      const response = await Http.get('api/surveys/target_survey', {
        params: {
          targetSlug: targetSlug,
          surveySlug: surveySlug
        }
      })
      return response.data
    } catch (err) {
      throw err
    }
  },
  async getSurveyGroup (targetSlug) {
    try {
      const response = await Http.get('api/surveys/survey_group', {
        params: {
          targetSlug: targetSlug,
        }
      })
      return response.data
    } catch (err) {
      throw err
    }
  }
}
