<template>
  <v-dialog v-model="dialog" max-width="400px" width="auto" persistent>
    <v-card class="card">
      <div class="card-header">
        <v-icon class="icon" color="#E47E67">{{ icon }}</v-icon>
        <h2 class="title">{{ title }}</h2>
        <p class="description" v-if="description">{{ description }}</p>
      </div>
      <div class="buttons-container">
        <button class="btn btn-dark" @click="handleCancel">{{ cancelText }}</button>
        <button class="btn btn-primary" @click="handleConfirm">{{ confirmText }}</button>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
// imports

export default {
  name: 'MapPointConfirmation',
  data () {
    return {}
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: 'mdi-information-outline'
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    },
    confirmText: {
      type: String,
      required: true
    },
    cancelText: {
      type: String,
      required: true
    },
  },
  components: {
  },
  computed: {
    dialog: {
      /**
       * Getter method for the dialog
       * @return {Boolean} 
       */
      get () {
        return this.value
      },
    
      /**
       * Setter method for the dialog 
       * @param {Boolean} dialog 
       */
      set (dialog) {
        this.$emit('input', dialog)
      } 
    },
  },
  methods: {
    handleConfirm () {
      this.$emit('confirm')
    },
    handleCancel () {
      this.$emit('cancel')
    }
  },
}

</script>

<style lang="scss" scoped>
.card {
  z-index: 999;
  padding: 20px;
  .card-header {
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;

    .icon {
      font-size: 62px;
    }

    .title {
      margin-bottom: 10px;
    }

    .description {
      margin-bottom: 5px;
      max-width: 300px;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .btn {
      border-radius: 5px;
      color: white;
      font-weight: bold;
      padding: 10px 20px;

      &.btn-dark {
        background-color: #595959;
      }

      &.btn-primary {
        background-color: #E47E67;
      }
    }
  }
}
</style>

