// Haversine formula
const CalculateStraightDistance = (departure, destination) => {
  var dep_lat = departure.lat
  var dep_lng = departure.lng
  var dest_lat = destination.lat
  var dest_lng = destination.lng

  var R = 6371

  var x1 = dep_lat - dest_lat
  var dLat = x1 * Math.PI / 180

  var x2 = dep_lng - dest_lng
  var dLng = x2 * Math.PI / 180

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(dep_lat * Math.PI / 180) * Math.cos(dest_lat * Math.PI / 180) * Math.sin(dLng / 2) * Math.sin(dLng / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  var d = R * c * 1000
  return d.toFixed(3)
}

export default CalculateStraightDistance
