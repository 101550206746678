<template>
  <v-text-field
    v-model="textValue"
    :label="label"
    color="#000080"
    solo
    :error-messages="errorMessages"
  >
  </v-text-field>
</template>
<script>
import { mapGetters } from 'vuex'
import {translationMixins} from '@/mixins/translation-mixins'
export default {
  name: 'FillerText',
  mixins: [translationMixins],
  props: {
    value: String,
    invalid: Boolean,
    errors: {
      type: Array,
      default: () => []
    },
    locale: {
      type: String,
      default: 'fi'
    },
  },
  computed: {
    ...mapGetters('translations', {
      translations: 'translations',
    }),
    textValue: {
      /**
       * Getter method for the textValue
       * @return {String} 
       */
      get () {
        return this.value 
      },
    
      /**
       * Setter method for the textValue 
       * @param {String} textValue 
       */
      set (textValue) {
        this.$emit('input', textValue)
      }, 
    },
    label () {
      return this.staticTranslation('labels.freeText')
    },
    errorMessages () {
      return this.invalid
        ? [...this.errors, ...[this.staticTranslation('messages.required')]] 
        : ''
    }
  },
  methods: {
    staticTranslation(key) {
      return this.getStaticTranslation(this.locale, key, this.translations, this.$t(key))
    },
  }
}
</script>
<style>
.v-messages.theme--light.error--text{
  color:white!important;
}
</style>